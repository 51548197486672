import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import AddFuelReceiptForm from "./AddFuelReceiptForm";
import useCreateFuelReceipt from "@/hooks/useCreateFuelReceipt";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

export default function AddFuelReceipt({ vehicleData }) {
  // hook to post receipt
  const { submitFuelReceipt, data } = useCreateFuelReceipt();

  // State to control dialog visibility
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Close dialog function
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // Effect hook to check data loading
  useEffect(() => {
    if (data) {
      closeDialog();
      window.location.reload();
    }
  }, [data]);

  return (
    <Dialog isOpen={isDialogOpen} onDismiss={closeDialog}>
      <DialogTrigger asChild>
        <Button variant="outline" onClick={() => setIsDialogOpen(true)}>
          + Add Fuel Receipt
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Fuel Receipt</DialogTitle>
        </DialogHeader>
        <AddFuelReceiptForm
          vehicleData={vehicleData}
          submitFuelReceipt={submitFuelReceipt}
        />
      </DialogContent>
    </Dialog>
  );
}
