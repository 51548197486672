import Map from "@/components/icons/Map";
import { FuelIcon } from "lucide-react";
import Funnel from "@/components/icons/Funnel";
import DollarIcon from "@/components/icons/DollarIcon";
import useVehicleMetrics from "@/hooks/useVehicleMetrics";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";

// ---- Default export KeyMetrics component -------------------------------------
export default function KeyMetrics({
  data,
  startDate,
  endDate,
  vehicleProfit,
}) {
  const { data: vehicleMetrics, loading } = useVehicleMetrics(
    startDate,
    endDate,
    data.id
  );

  // ---- Metrics Data -------------------------------------
  const metricsData = [
    {
      value: `${vehicleProfit.amount} ${vehicleProfit.currency}`,
      description: "Total Profit",
      icon: <DollarIcon />,
    },
    {
      value: vehicleMetrics ? vehicleMetrics.total_distance : "",
      description: "Total distance travelled",
      icon: <Map />,
    },
    {
      value: vehicleMetrics ? vehicleMetrics.total_fuel_consumed : "",
      description: "Total fuel consumption",
      icon: <FuelIcon />,
    },
    {
      value: vehicleMetrics ? vehicleMetrics.fuel_efficiency : "",
      description: "Fuel Efficiency",
      icon: <Funnel />,
    },
  ];

  if (loading) {
    return (
      <div className="flex items-center h-full justify-center bg-paper rounded-lg">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-paper h-full rounded-lg p-4 space-y-4">
      <span className="font-semibold">Key Metrics</span>
      {metricsData.map((metric) => (
        <Metric key={`metric-${metric.description}`} metric={metric} />
      ))}
    </div>
  );
}

// ---- Single metric with icon, value, etc. -------------------------------------
function Metric({ metric }) {
  return (
    <div className="flex border p-2 rounded-lg hover:bg-slate-50">
      <div className="w-1/6 flex items-center justify-center">
        {metric.icon}
      </div>
      <div className="flex-1 flex-col">
        <div>
          <span className="metric-value text-xl font-semibold text-slate-700">
            {metric.value}
          </span>
        </div>
        <div>
          <span className="font-light text-slate-500">
            {metric.description}
          </span>
        </div>
      </div>
    </div>
  );
}
