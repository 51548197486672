import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import useSMSImmobilize from "@/hooks/useSMSImmobilize";

export default function RemobilizeDialog({ selectedVehicle }) {
  // State to control dialog visibility
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { submitSMSImmobilize: submitSMSImmobilize } = useSMSImmobilize();

  // Close dialog function
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // Function to update the input value state
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Check if the input value is 'remobilize'
  const isInputCorrect = inputValue.toLowerCase() === "remobilize";

  return (
    <Dialog isOpen={isDialogOpen} onDismiss={closeDialog}>
      <DialogTrigger asChild>
        <Button
          className="w-full"
          onClick={() => setIsDialogOpen(true)}
        >
          Remobilize Vehicle
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>WARNING: VEHICLE REMOBILIZATION INITIATION</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col space-y-8">
          <div>
            You are about to remobilize a vehicle. This action will remotely
            re-enable the vehicle's engine, allowing it to be driven.
          </div>
         
          <div>
            Type 'remobilize' below to confirm your understanding and intent to
            proceed.
          </div>
          <Input
            placeholder="remobilize"
            value={inputValue}
            onChange={handleInputChange}
          />
          <Button
            variant={isInputCorrect ? "default" : "disabled"}
            disabled={!isInputCorrect}
            onClick={() => {
              submitSMSImmobilize(selectedVehicle.tracker_id);
              window.location.reload();
            }}
          >
            Remobilize Vehicle
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
