import * as z from "zod";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Separator } from "@/components/ui/separator";
import { zodResolver } from "@hookform/resolvers/zod";
import { DatePicker } from "@/components/ui/datepicker";
import supportedCurrencies from "@/lib/supportedCurrencies";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "@/components/ui/form";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectItem,
  SelectContent,
} from "@/components/ui/select";

// define schema
const formSchema = z.object({
  vehicle: z.number(),
  client_name: z.string().max(100),
  invoice_amount: z.number().min(0).max(1000000),
  invoice_amount_currency: z.string(),
  invoice_date: z.date(),
  invoice_number: z.string().optional(),
  memo: z.string().optional(),
});

export default function AddInvoiceForm({ vehicleData, submitInvoice }) {
  // create form
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      vehicle: vehicleData.id,
      client_name: "",
      invoice_amount: "",
      invoice_amount_currency: "KES",
      invoice_date: new Date(),
      invoice_number: "",
      memo: "",
    },
  });

  async function onSubmit(values) {
    // Clone the values object to avoid mutating the original object
    let formattedValues = { ...values };

    // Check if the date field exists and is a Date object
    if (formattedValues.invoice_date instanceof Date) {
      // Format the date to YYYY-MM-DD
      const year = formattedValues.invoice_date.getFullYear();
      const month = (formattedValues.invoice_date.getMonth() + 1)
        .toString()
        .padStart(2, "0"); // Adding 1 because months are 0-indexed
      const day = formattedValues.invoice_date
        .getDate()
        .toString()
        .padStart(2, "0");

      formattedValues.invoice_date = `${year}-${month}-${day}`;
    }
    await submitInvoice(formattedValues);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="bg-paper">
        <FormField
          name="vehicle"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Vehicle</FormLabel>
              <FormControl>
                <Input
                  disabled
                  {...field}
                  value={vehicleData.registration_number}
                  placeholder={vehicleData.registration_number}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="client_name"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Client Name</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Client Name"
                  onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="invoice_amount"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Invoice Amount</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  placeholder="Amount"
                  step={0.01}
                  onChange={(e) => field.onChange(Number(e.target.value))}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="invoice_amount_currency"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Invoice Amount Currency</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select currency" />
                  </SelectTrigger>
                  <SelectContent>
                    {supportedCurrencies.map((currency) => (
                      <SelectItem key={currency} value={currency}>
                        {currency}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="invoice_date"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Date</FormLabel>
              <FormControl>
                <DatePicker
                  onDateSelect={(date) => form.setValue("date", date)}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="mb-8" />
        <Separator className="my-8" />
        <FormField
          control={form.control}
          name="invoice_number"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Invoice Number (optional)</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Invoice Number"
                  onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="memo"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Memo (optional)</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  placeholder="Memo"
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button className="w-full mt-4" type="submit">
          + Add Invoice
        </Button>
      </form>
    </Form>
  );
}
