import { useNavigate } from "react-router-dom";
import { CarIcon, TruckIcon } from "lucide-react";
import useCreateRecentVehicleSearch from "@/hooks/useCreateRecentVehicleSearch";

const vehicleLabelMapping = {
  car: "Car",
  truck: "Truck",
};

const vehicleIconMapping = {
  car: <CarIcon color="rgb(239 68 68)" />,
  truck: <TruckIcon color="rgb(37 99 235)" />,
};

export default function SearchResults({ data }) {
  const { createRecentVehicleSearch } = useCreateRecentVehicleSearch();

  return (
    <div className="flex-col">
      <div className="text-sm font-semibold text-gray-500 px-4 pt-4">
        Search Results
      </div>
      {data.map((vehicle) => (
        <VehicleCard
          key={vehicle.id}
          vehicle={vehicle}
          createRecentVehicleSearch={createRecentVehicleSearch}
        />
      ))}
    </div>
  );
}

// ---- Card for each vehicle in search ----------------------------------
function VehicleCard({ vehicle, createRecentVehicleSearch }) {
  const navigate = useNavigate();
  return (
    <div
      className="bg-blue-50 border mt-4 py-2 px-4 flex rounded-lg hover:bg-blue-100 cursor-pointer"
      onClick={() => {
        createRecentVehicleSearch(vehicle.id);
        navigate(`/fleet/${vehicle.id}`);
        window.location.reload();
      }}
    >
      <div className="flex flex-col justify-center p-4">
        {vehicleIconMapping[vehicle.vehicle_type]}
      </div>
      <div>
        <div className="font-semibold">{vehicle.registration_number}</div>
        <div className="font-light">
          {vehicleLabelMapping[vehicle.vehicle_type]}
        </div>
      </div>
    </div>
  );
}
