import React from "react";
import Search from "./search";
//import Notifications from "./Notifications";
import User from "./User";

export default function TopBar() {
  return (
    <div className="flex lg:justify-between">
      <Search />

      <div className="hidden lg:flex space-x-4">
        {/*<Notifications />*/}
        <User />
      </div>
    </div>
  );
}
