import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";

import MenuItems from "../MenuItems";
import MenuSheetItem from "./MenuSheetItem";
import MenuIcon from "@/components/icons/MenuIcon";

export default function MenuSheet({ activePage, handlePageClick }) {
  return (
    <Sheet>
      <div className="rounded-3xl p-2">
        <SheetTrigger>
          <MenuIcon />
        </SheetTrigger>
      </div>
      <SheetContent className="bg-slate-200 w-5/6">
        <SheetHeader className="mt-8">
          <SheetTitle className="text-4xl text-left font-light mb-8">
            MENU
          </SheetTitle>
          {MenuItems.map((page) => (
            <MenuSheetItem
              key={page.name}
              item={page}
              activePage={activePage}
              handlePageClick={handlePageClick}
            />
          ))}
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
}
