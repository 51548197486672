import React from "react";
import LoginForm from "./LoginForm";

export default function Login() {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="bg-paper w-1/4 p-8 rounded-lg">
        <LoginForm />
      </div>
    </div>
  );
}
