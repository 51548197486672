import { useAuth } from "@/auth/AuthContext";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@/components/icons/LogoutIcon";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

export default function Logout() {
  const { removeAuthInfo } = useAuth();
  const navigate = useNavigate();

  return (
    <Dialog>
      <DialogTrigger>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className="flex justify-center p-4 text-white hover:bg-blue-200 hover:text-black cursor-pointer rounded-lg">
                <LogoutIcon />
              </div>
            </TooltipTrigger>
            <TooltipContent>Logout</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DialogTrigger>
      <DialogContent className="w-1/5">
        <DialogHeader>
          <DialogTitle>Confirm Logout</DialogTitle>
          <DialogDescription>
            <div className="flex flex-col w-full space-y-2">
              <div>Are you sure you want to logout?</div>
              <div>
                <DialogClose asChild>
                  <Button
                    onClick={() => {
                      removeAuthInfo();
                      navigate("/login");
                    }}
                  >
                    Logout
                  </Button>
                </DialogClose>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
