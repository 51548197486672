import carRender from "@/assets/images/carRenderW300.png";
import formatDateToString from "@/utils/FormatDateToString";
import truckRender from "@/assets/images/truckRenderW300.png";
import useTotalFuelExpense from "@/hooks/useTotalFuelExpense";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";
import useTotalSparePartsExpense from "@/hooks/useTotalSparePartsExpense";

import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import useTotalInvoiceAmount from "@/hooks/useTotalInvoiceAmount";
import { useEffect } from "react";
import useInvoiceAmountOverTime from "@/hooks/useInvoiceAmountOverTime";

function VehicleOverview({
  data,
  currency,
  startDate,
  endDate,
  setVehicleProfit,
}) {
  // pull total fuel expense
  const { data: fuelExpenseData, loading: loadingFuel } = useTotalFuelExpense(
    startDate,
    endDate,
    currency,
    data.id
  );

  // pull total spare parts expense
  const { data: partsExpenseData, loading: loadingParts } =
    useTotalSparePartsExpense(startDate, endDate, currency, data.id);

  // pull invoice amount
  const { data: invoicesData, loading: loadingInvoices } =
    useTotalInvoiceAmount(startDate, endDate, currency, data.id);

  // pull revenue over time
  const { data: invoiceOverTime, loading: loadingInvoiceOverTime } =
    useInvoiceAmountOverTime(startDate, endDate, currency, data.id);

  useEffect(() => {
    const profit =
      (invoicesData ? invoicesData.total_invoice_amount : 0) -
      ((fuelExpenseData ? fuelExpenseData.total_fuel_expense : 0) +
        (partsExpenseData ? partsExpenseData.total_spare_parts_expense : 0));
    setVehicleProfit({
      amount: formatNumberWithCommas(profit),
      currency: currency,
    });
  }, [
    invoicesData,
    fuelExpenseData,
    partsExpenseData,
    currency,
    setVehicleProfit,
  ]);

  // Function to calculate the ticks for the X-axis
  const calculateTicks = (data) => {
    if (!data || data.length === 0) {
      // If data is null, undefined, or empty, return an empty array
      return [];
    }

    if (data.length <= 3) {
      // If there are 3 or fewer points, use all dates as ticks
      return data.map((d) => d.date);
    } else {
      // If more, use first, middle, and last dates
      const middleIndex = Math.floor(data.length / 2);
      return [data[0].date, data[middleIndex].date, data[data.length - 1].date];
    }
  };

  // Calculate the ticks to use on the X-axis
  const xAxisTicks = calculateTicks(invoiceOverTime);

  if (loadingFuel | loadingParts | loadingInvoices | loadingInvoiceOverTime) {
    return (
      <div className="truck-overview-container h-full w-full items-center justify-center bg-paper p-4 space-x-4 rounded-lg flex">
        <LoadingSpinner />
      </div>
    );
  }

  const renderMapping = {
    car: carRender,
    truck: truckRender,
  };

  return (
    fuelExpenseData &&
    partsExpenseData &&
    invoicesData && (
      <div className="truck-overview-container w-full bg-paper p-4 space-x-4 rounded-lg flex">
        <div className="container-left w-1/4 flex flex-col space-y-10">
          <div className="container-header font-semibold">Overview</div>
          <div className="truck-image-container">
            {/* Render truck image here */}
            <img
              src={renderMapping[data.vehicle_type]}
              alt={data.vehicle_type}
            />
          </div>
          <div className="flex flex-col">
            <span className="text-center font-semibold text-lg">
              {data.registration_number}
            </span>
            <span className="text-center text-lg">
              {data.color} - {data.manufacturer} - {data.model}
            </span>
          </div>
        </div>

        <div className="container-right w-3/4 flex flex-col space-y-4">
          <div className="metrics-container flex space-x-2 justify-around">
            {/* Render metrics here */}
            <div className="metric flex flex-col">
              <span className="metric-value text-xl font-semibold text-slate-700">
                {formatNumberWithCommas(invoicesData.total_invoice_amount)}{" "}
                {invoicesData.total_invoice_amount_currency}
              </span>
              <span className="metric-label">Total Revenue</span>
            </div>
            <div className="metric flex flex-col">
              <span className="metric-value text-xl font-semibold text-slate-700">
                {formatNumberWithCommas(fuelExpenseData.total_fuel_expense)}{" "}
                {fuelExpenseData.total_fuel_expense_currency}
              </span>
              <span className="metric-label">Fuel Expense</span>
            </div>
            <div className="metric flex flex-col">
              <span className="metric-value text-xl font-semibold text-slate-700">
                {formatNumberWithCommas(
                  partsExpenseData.total_spare_parts_expense
                )}{" "}
                {partsExpenseData.total_spare_parts_expense_currency}
              </span>
              <span className="metric-label">Spare Parts Expense</span>
            </div>
          </div>
          {/* Render graph here */}
          <div className="graph-container mt-4">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={invoiceOverTime}
                margin={{
                  top: 40,
                  right: 60,
                  left: 100,
                  bottom: 20,
                }}
              >
                <CartesianGrid
                  horizontal={true}
                  vertical={false}
                  stroke="#ccc"
                  strokeDasharray="3 3"
                />
                <XAxis
                  stroke="#888"
                  strokeDasharray="3 3"
                  dataKey="date"
                  tickFormatter={formatDateToString}
                  ticks={xAxisTicks}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="total_invoice_amount"
                  name="Revenue (USD)"
                  stroke="#003366"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    )
  );
}

export default VehicleOverview;
