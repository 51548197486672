import React from "react";
import NotFoundRender from "@/components/icons/NotFoundRender";

export default function NoResults() {
  return (
    <div className="flex-col px-4 pt-4">
      <div className="text-sm font-semibold text-gray-500">Search Results</div>
      <div className="flex-col bg-slate-50 mt-4 p-4 space-y-4 rounded-lg">
        <div className="flex justify-center">
          <NotFoundRender />
        </div>
        <div className="flex justify-center uppercase font-semibold text-slate-600">
          No results found
        </div>
      </div>
    </div>
  );
}
