import Logo from "../Logo";
import Logout from "./Logout";
import NavItem from "./NavItem";
import MenuItems from "../MenuItems";

export default function DesktopNavbar({ activePage, handlePageClick }) {
  return (
    <div className="flex flex-col justify-center">
      <div className="hidden lg:flex flex-col h-screen px-2 py-8 bg-primary">
        <div className="flex-1">
          <div className="py-4 px-2 mb-4">
            <Logo />
          </div>
          <div className="flex flex-col space-y-5 pt-20 items-center">
            {MenuItems.map((page) => (
              <NavItem
                key={page.name}
                item={page}
                activePage={activePage}
                handlePageClick={handlePageClick}
              />
            ))}
          </div>
        </div>
        <Logout />
      </div>
    </div>
  );
}
