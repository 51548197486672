import deleteData from "@/utils/DeleteData";
import { useState } from "react";

function useDeleteFuelReceipt() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const deleteReceipt = async (id) => {
    setLoading(true);
    try {
      const result = await deleteData(`/api/fuel/receipts/delete/${id}/`);
      setData(result);
      setError(null);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { deleteReceipt, data, loading, error };
}

export default useDeleteFuelReceipt;
