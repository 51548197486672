import { useState } from "react";
import { postData } from "@/utils/PostData";

function useCreateRecentVehicleSearch() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const createRecentVehicleSearch = async (vehicleId) => {
    setLoading(true);
    setData(null);
    setError(null);

    try {
      const result = await postData("/api/vehicles/create-recent-search/", {
        vehicle_id: vehicleId,
      });
      setData(result);
    } catch (e) {
      setError(e.message || "An error occurred while updating search history");
    } finally {
      setLoading(false);
    }
  };

  return { createRecentVehicleSearch, data, loading, error };
}

export default useCreateRecentVehicleSearch;
