import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Returns total fuel expense for a truck (if specified), date range, and currency
function useInvoiceAmountOverTime(
  startDate,
  endDate,
  currency,
  vehicleId = null
) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      if (!startDate || !endDate || !currency) {
        setError("startDate, endDate or currency missing");
        setData(null);
        setLoading(false);
        return;
      }

      try {
        let url = `/api/financials/invoices/total-amount-over-time?start_date=${startDate}&end_date=${endDate}&currency=${currency}`;
        if (vehicleId) {
          url += `&vehicle_id=${vehicleId}`;
        }
        const result = await fetchData(url);
        setData(result);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [startDate, endDate, currency]);

  return { data, loading, error };
}

export default useInvoiceAmountOverTime;
