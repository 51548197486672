import { useState } from "react";
import dateOptions from "../../../../lib/dateOptions";
import { Button } from "@/components/ui/button";
import CalendarDays from "@/components/icons/CalendarDays";
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import CustomDatePicker from "./CustomDatePicker";

// ----- Date Selector Deault Export -----------------------------
export default function DateSelector({ setStartDate, setEndDate }) {
  const [selected, setSelected] = useState("This Month");
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State to track dialog open/close

  // Function to close the dialog
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger>
        <div
          className="flex space-x-2 py-2 px-4 bg-app rounded-lg border hover:bg-slate-200"
          variant="outline"
        >
          <CalendarDays />
          <div>{selected}</div>
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Select Date Range</DialogTitle>
        </DialogHeader>

        {/* First row of date buttons */}
        <div className="flex ">
          <div className="flex flex-col w-2/3">
            <div className="flex space-x-2 ">
              {Object.keys(dateOptions)
                .slice(0, 3)
                .map((date) => (
                  <DateButton
                    key={`date-${date}`}
                    text={date}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setSelected={setSelected}
                    isSelected={date === selected}
                  />
                ))}
            </div>

            {/* Second row of date buttons */}
            <div className="flex pt-2 space-x-2 ">
              {Object.keys(dateOptions)
                .slice(3, 6)
                .map((date) => (
                  <DateButton
                    key={`date-${date}`}
                    text={date}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setSelected={setSelected}
                    isSelected={date === selected}
                  />
                ))}
            </div>
          </div>
          <div className="flex-1 flex-col">
            <div className="text-sm font-semibold text-gray-600 mb-1">
              Custom Date Range
            </div>
            <CustomDatePicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setSelected={setSelected}
              closeDialog={closeDialog}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
// ----- Button with Calendar ----------------------------------
function DateButton({
  text,
  setStartDate,
  setEndDate,
  setSelected,
  isSelected,
}) {
  return (
    <DialogClose asChild>
      <Button
        className="flex space-x-2 bg-app hover:bg-slate-200"
        disabled={isSelected}
        variant="outline"
        onClick={() => {
          setStartDate(dateOptions[text]["startDate"]);
          setEndDate(dateOptions[text]["endDate"]);
          setSelected(text);
        }}
      >
        <div>{text}</div>
      </Button>
    </DialogClose>
  );
}
