import useScorecard from "@/hooks/useScorecard";
import useDriverScore from "@/hooks/useDriverScore";
import useDriverDetails from "@/hooks/useDriverDetails";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import defaultDriverImage from "@/assets/images/driver-default.png";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { TimerIcon } from "lucide-react";

export default function DriverScorecard({ vehicleData, startDate, endDate }) {
  const COLORS = [
    "#FF0000",
    "#FF4500",
    "#FF7F50",
    "#FFD700",
    "#9ACD32",
    "#008000",
  ];

  const { data: scorecard, loading: loadingScorecard } = useScorecard(
    startDate,
    endDate,
    vehicleData.id
  );

  const { data: driverScore, loading: loadingDriverScore } = useDriverScore(
    startDate,
    endDate,
    vehicleData.id
  );

  const { data: driverDetails, loading: loadingDriverDetails } =
    useDriverDetails(vehicleData.id);

  // Calculate color based on the score
  const colorIndex = Math.min(
    COLORS.length - 1,
    Math.floor((driverScore ? driverScore.percentile : 1) / 20)
  );
  const fillColor = COLORS[colorIndex];

  // Create a data array for the chart
  const data = [
    { name: "Score", value: driverScore ? driverScore.percentile : 0 },
    {
      name: "Remaining",
      value: 100 - (driverScore ? driverScore.percentile : 0),
    },
  ];

  if (loadingScorecard | loadingDriverScore) {
    return (
      <div className="driver-scorecard-container w-3/5 bg-paper p-4 space-x-4 rounded-lg flex flex-col items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="driver-scorecard-container w-3/5 bg-paper p-4 space-x-4 rounded-lg flex flex-col">
      <span className="font-semibold">Driver Scorecard</span>
      <div className="flex h-full items-center space-x-2">
        <div className="flex flex-col w-1/4 h-[90%] items-center justify-center space-y-4 border rounded-lg">
          <div className="font-semibold text-sm text-center">
            DRIVER PROFILE
          </div>
          <img
            src={driverDetails.image ? driverDetails.image : defaultDriverImage}
            alt="Avatar"
            className="rounded-full border"
            style={{ width: "150px", height: "150px" }}
          />
          <div className="flex flex-col items-center">
            <div className="font-semibold">
              {driverDetails ? driverDetails.name : ""}
            </div>
            <div>{driverDetails ? driverDetails.phone_number : ""}</div>
          </div>
        </div>
        <div className="flex flex-col w-1/4 h-[90%] border items-center justify-center rounded-lg">
          <div className="font-semibold text-sm text-center pt-5">
            OVERALL SCORE
          </div>
          <ResponsiveContainer width="100%" height={250}>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill={fillColor}
                paddingAngle={5}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={index === 0 ? fillColor : "#F0F0F0"}
                  />
                ))}
              </Pie>
              <text
                x="51%"
                y="50%"
                dy={8}
                textAnchor="middle"
                fill={"rgb(23 37 84)"}
                className="text-4xl font-light"
              >
                {`${Math.floor(driverScore ? driverScore.percentile : 1)}%`}
              </text>
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="flex flex-col w-1/4 h-[90%] items-center pt-7 space-y-4 border rounded-lg">
          <div className="font-semibold text-sm text-center">DRIVING TIME</div>
          <div className="pt-12">
            <TimerIcon />
          </div>
          <div className="text-3xl">{scorecard.total_driving_time}</div>
        </div>

        <div className="flex flex-col w-1/4 h-[90%] items-center pt-8 space-y-4 border rounded-lg">
          <div className="font-semibold text-sm text-center">IDLE TIME</div>
          <div className="pt-12">
            <TimerIcon />
          </div>
          <div className="text-3xl">{scorecard.total_idle_time}</div>
        </div>
      </div>
    </div>
  );
}
