// styles
import React from "react";
import "@/styles/index.css";

// components
import Navbar from "@/components/navbar";

// routing
import routes from "@/routes/RouteConfig";
import AdminRoute from "@/auth/AdminRoute";
import PrivateRoute from "@/auth/PrivateRoute";
import LocationRoute from "@/auth/LocationRoute";
import { AuthProvider } from "@/auth/AuthContext";
import DashboardRoute from "@/auth/DashboardRoute";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Utility to handle console methods in production
const handleConsoleMethods = () => {
  if (process.env.NODE_ENV === "production") {
    console.log = console.warn = console.error = () => {};
    // Extend as needed
  }
};

// Mapping route types to their respective components
const routeComponents = {
  standard: React.Fragment,
  private: PrivateRoute,
  location: LocationRoute,
  dashboard: DashboardRoute,
  admin: AdminRoute,
};

// Function to determine and render the correct route component
const renderRoute = (route, index) => {
  const Element = route.element;
  const RouteComponent = routeComponents[route.routeType] || React.Fragment;

  return (
    <Route
      key={index}
      path={route.path}
      element={<RouteComponent>{React.createElement(Element)}</RouteComponent>}
    />
  );
};

// App
function App() {
  handleConsoleMethods();

  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="app-bg flex flex-col h-screen lg:flex-row">
          <Navbar />
          <Routes>{routes.map(renderRoute)}</Routes>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
