import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import useCreatePartsReceipt from "@/hooks/useCreatePartsReceipt";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import AddPartsReceiptForm from "./AddPartsReceiptForm";

export default function AddPartsReceipt({ vehicleData }) {
  // hook to post receipt
  const { submitPartsReceipt, data } = useCreatePartsReceipt();

  // State to control dialog visibility
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Close dialog function
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // Effect hook to check data loading
  useEffect(() => {
    if (data) {
      closeDialog();
      window.location.reload();
    }
  }, [data]);

  return (
    <Dialog isOpen={isDialogOpen} onDismiss={closeDialog}>
      <DialogTrigger asChild>
        <Button variant="outline" onClick={() => setIsDialogOpen(true)}>
          + Add Spare Parts Receipt
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Spare Parts Receipt</DialogTitle>
        </DialogHeader>
        <AddPartsReceiptForm
          vehicleData={vehicleData}
          submitPartsReceipt={submitPartsReceipt}
        />
      </DialogContent>
    </Dialog>
  );
}
