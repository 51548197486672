import axios from "axios";

export async function postData(url, data) {
  const accessToken = localStorage.getItem("access_token");

  if (!accessToken) {
    throw new Error("No access token found in local storage.");
  }

  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to post data: ${error.response?.statusText || error.message}`
    );
  }
}
