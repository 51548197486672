import TopRow from "../toprow";
import { useState } from "react";
import FuelHeadline from "./FuelHeadline";
import AppLayout from "@/layouts/AppLayout";
import dateOptions from "@/lib/dateOptions";
import FuelPriceOverTime from "./FuelPriceOverTime";
import DistributionBarChart from "@/components/barchart";
import useTotalFuelExpense from "@/hooks/useTotalFuelExpense";
import useFuelPriceOverTime from "@/hooks/useFuelPriceOverTime";
import FuelStatsByStationTable from "./FuelStatsByStationTable";
import useFuelStatsByStation from "@/hooks/useFuelStatsByStation";
import useTotalFuelExpenseByVehicle from "@/hooks/useTotalFuelExpenseByVehicle";

export default function FuelDashboard() {
  const [currency, setCurrency] = useState("USD");
  const [startDate, setStartDate] = useState(
    dateOptions["This Month"]["startDate"]
  );
  const [endDate, setEndDate] = useState(dateOptions["This Month"]["endDate"]);

  const { data: fuelExpense, loading: loadingFuelExpense } =
    useTotalFuelExpense(startDate, endDate, currency);

  const { data: fuelExpenseByVehicle, loading: loadingFuelExpenseByVehicle } =
    useTotalFuelExpenseByVehicle(startDate, endDate, currency);

  const { data: fuelStatsByStation, loading: loadingFuelStatsByStation } =
    useFuelStatsByStation(startDate, endDate, currency);

  const { data: fuelPriceOverTime, loading: loadingFuelPriceOverTime } =
    useFuelPriceOverTime(startDate, endDate, currency);

  return (
    <AppLayout>
      <TopRow
        setCurrency={setCurrency}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        prevStartDate={fuelExpense ? fuelExpense.prev_start_date : null}
        prevEndDate={fuelExpense ? fuelExpense.prev_end_date : null}
      />

      <FuelHeadline
        startDate={startDate}
        endDate={endDate}
        currency={currency}
        fuelExpense={fuelExpense}
        loadingFuelExpense={loadingFuelExpense}
      />
      <DistributionBarChart
        data={fuelExpenseByVehicle}
        loading={loadingFuelExpenseByVehicle}
        chartTitle="Fuel Expense by Vehicle"
        dataKey="total_fuel_expense"
        dataLabel="Fuel Expense"
      />
      <div className="flex w-full space-x-2 h-2/5">
        <FuelStatsByStationTable
          data={fuelStatsByStation}
          loading={loadingFuelStatsByStation}
          endDate={endDate}
        />

        <FuelPriceOverTime
          data={fuelPriceOverTime}
          loading={loadingFuelPriceOverTime}
        />
      </div>
    </AppLayout>
  );
}
