import { useNavigate } from "react-router-dom";
import { CarIcon, TruckIcon } from "lucide-react";
import ReloadIcon from "@/components/icons/ReloadIcon";
import useRecentVehicleSearches from "@/hooks/useRecentVehicleSearches";

const vehicleLabelMapping = {
  car: "Car",
  truck: "Truck",
};

const vehicleIconMapping = {
  car: <CarIcon color="rgb(239 68 68)" />,
  truck: <TruckIcon color="rgb(37 99 235)" />,
};

export default function Recents() {
  const { data } = useRecentVehicleSearches();
  return (
    <div className="flex-col">
      <div className="text-sm font-semibold text-gray-500 px-4 pt-4">
        Recent Searches
      </div>
      {data.map((vehicle) => (
        <VehicleCard vehicle={vehicle} />
      ))}
    </div>
  );
}

// ---- Card for each vehicle in search ----------------------------------
function VehicleCard({ vehicle }) {
  const navigate = useNavigate();
  return (
    <div
      className="bg-slate-50 border mt-4 py-2 px-4 flex rounded-lg hover:bg-slate-100 cursor-pointer"
      onClick={() => {
        navigate(`/fleet/${vehicle.vehicle_id}`);
        window.location.reload();
      }}
    >
      <div className="flex flex-col justify-center p-4">
        {vehicleIconMapping[vehicle.vehicle_type]}
      </div>
      <div>
        <div className="font-semibold">{vehicle.registration_number}</div>
        <div className="font-light">
          {vehicleLabelMapping[vehicle.vehicle_type]}
        </div>
      </div>
      <div className="flex flex-col ml-auto justify-center">
        <ReloadIcon />
      </div>
    </div>
  );
}
