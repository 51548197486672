import HeadlineNumbers from "@/components/headline";
import RevenueIcon from "@/components/icons/RevenueIcon";
import formatDateToString from "@/utils/FormatDateToString";
import CalculatorIcon from "@/components/icons/CalculatorIcon";
import formatNumberWithLetters from "@/utils/formatNumberWithLetters";

import useTotalInvoiceCount from "@/hooks/useTotalInvoiceCount";
import useInvoiceVehicleCount from "@/hooks/useInvoiceVehicleCount";
import useInvoiceClientCount from "@/hooks/useInvoiceClientCount";
import useAverageInvoiceAmount from "@/hooks/useAverageInvoiceAmount";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";

export default function InvoicesHeadline({
  startDate,
  endDate,
  currency,
  totalInvoiceAmount,
  loadingTotalInvoiceAmount,
}) {
  const { data: totalInvoiceCount, loading: loadingTotalInvoiceCount } =
    useTotalInvoiceCount(startDate, endDate);

  const { data: invoiceVehicleCount, loading: loadingInvoiceVehicleCount } =
    useInvoiceVehicleCount(startDate, endDate);

  const { data: invoiceClientCount, loading: loadingInvoiceClientCount } =
    useInvoiceClientCount(startDate, endDate);

  const { data: averageInvoiceAmount, loading: loadingAverageInvoiceAmount } =
    useAverageInvoiceAmount(startDate, endDate, currency);

  const InvoicesCard = [
    {
      name: "Total Revenue",
      icon: <RevenueIcon />,
      value: totalInvoiceAmount
        ? `${formatNumberWithLetters(
            totalInvoiceAmount.total_invoice_amount
          )} ${totalInvoiceAmount.total_invoice_amount_currency}`
        : "",
      percentage: totalInvoiceAmount ? totalInvoiceAmount.percent_change : "",
      desc: `Total revenue from invoices generated between ${formatDateToString(
        startDate
      )} and ${formatDateToString(endDate)}`,
      period: totalInvoiceAmount
        ? `This amount is ${
            totalInvoiceAmount.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            totalInvoiceAmount.percent_change
          )}% from the previous period: ${formatDateToString(
            totalInvoiceAmount.prev_start_date
          )} to ${formatDateToString(totalInvoiceAmount.prev_end_date)}.`
        : "",
      loading: loadingTotalInvoiceAmount,
    },
    {
      name: "Total Number of Invoices",
      icon: <CalculatorIcon fillColor="rgb(37 99 235)" />,
      value: totalInvoiceCount
        ? `${formatNumberWithLetters(totalInvoiceCount.total_invoices)}`
        : "",
      percentage: totalInvoiceCount ? totalInvoiceCount.percent_change : "",
      desc: `Total number of invoice generated from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: totalInvoiceCount
        ? `This amount is ${
            totalInvoiceCount.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            totalInvoiceCount.percent_change
          )}% from the previous period: ${formatDateToString(
            totalInvoiceCount.prev_start_date
          )} to ${formatDateToString(totalInvoiceCount.prev_end_date)}.`
        : "",
      loading: loadingTotalInvoiceCount,
    },
    {
      name: "Vehicles With Invoices",
      icon: <CalculatorIcon fillColor="rgb(37 99 235)" />,
      value: invoiceVehicleCount
        ? `${formatNumberWithLetters(invoiceVehicleCount.number_of_vehicles)}`
        : "",
      percentage: invoiceVehicleCount ? invoiceVehicleCount.percent_change : "",
      desc: `Total number of vehicles with invoices from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: invoiceVehicleCount
        ? `This amount is ${
            invoiceVehicleCount.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            invoiceVehicleCount.percent_change
          )}% from the previous period: ${formatDateToString(
            invoiceVehicleCount.prev_start_date
          )} to ${formatDateToString(invoiceVehicleCount.prev_end_date)}.`
        : "",
      loading: loadingInvoiceVehicleCount,
    },
    {
      name: "Total Number of Clients",
      icon: <CalculatorIcon fillColor="rgb(37 99 235)" />,
      value: invoiceClientCount
        ? `${formatNumberWithLetters(invoiceClientCount.total_clients)}`
        : "",
      percentage: invoiceClientCount ? invoiceClientCount.percent_change : "",
      desc: `Total number of clients with invoices from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: invoiceClientCount
        ? `This amount is ${
            invoiceClientCount.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            invoiceClientCount.percent_change
          )}% from the previous period: ${formatDateToString(
            invoiceClientCount.prev_start_date
          )} to ${formatDateToString(invoiceClientCount.prev_end_date)}.`
        : "",
      loading: loadingInvoiceClientCount,
    },
    {
      name: "Average Invoice Amount",
      icon: <RevenueIcon />,
      value: averageInvoiceAmount
        ? `${formatNumberWithCommas(
            averageInvoiceAmount.average_invoice_amount
          )} ${averageInvoiceAmount.average_invoice_amount_currency}`
        : "",
      percentage: averageInvoiceAmount
        ? averageInvoiceAmount.percent_change
        : "",
      desc: `Average invoice amount from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: averageInvoiceAmount
        ? `This amount is ${
            averageInvoiceAmount.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            averageInvoiceAmount.percent_change
          )}% from the previous period: ${formatDateToString(
            averageInvoiceAmount.prev_start_date
          )} to ${formatDateToString(averageInvoiceAmount.prev_end_date)}.`
        : "",
      loading: loadingAverageInvoiceAmount,
    },
  ];

  return <HeadlineNumbers data={InvoicesCard} />;
}
