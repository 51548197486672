import { useState } from "react";
import { postData } from "@/utils/PostData";

function useCreatePartsReceipt() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitPartsReceipt = async (partsReceiptData) => {
    setLoading(true);
    setData(null);
    setError(null);

    try {
      const result = await postData(
        "/api/parts/receipts/create/",
        partsReceiptData
      );
      setData(result);
    } catch (e) {
      setError(e.message || "An error occurred while submitting the receipt");
    } finally {
      setLoading(false);
    }
  };

  return { submitPartsReceipt, data, loading, error };
}

export default useCreatePartsReceipt;
