import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

export default function User() {
  const firstName = localStorage.getItem("first_name");
  const isStaff = localStorage.getItem("is_staff") === "true";

  return (
    <div className="flex px-4 py-2 space-x-2 hover:cursor-pointer hover:bg-slate-200 rounded-lg">
      <Avatar>
        <AvatarImage src="https://i.stack.imgur.com/SE2cv.jpg" />
        <AvatarFallback>CN</AvatarFallback>
      </Avatar>
      <div className="flex flex-col">
        <div className="font-bold">{firstName}</div>
        <div className="font-light text-sm">
          {isStaff ? "Admin User" : "Standard User"}
        </div>
      </div>
    </div>
  );
}
