import TopBar from "@/components/topbar";

export default function AppLayout({ children }) {
  return (
    <div className="flex flex-col p-4 space-y-4 lg:flex-1 lg:p-10 overflow-auto">
      <TopBar />
      {children}
    </div>
  );
}
