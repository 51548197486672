import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Link } from "react-router-dom";

export default function NavItem({ item, activePage, handlePageClick }) {
  const isActive = item.name === activePage;
  return (
    <Link key={item.name} to={item.route}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <div
              className={`flex p-4 hover:cursor-pointer rounded-lg text-white ${
                isActive ? "bg-blue-800" : "hover:bg-blue-200 hover:text-black"
              }`}
              onClick={() => {
                handlePageClick(item.name);
              }}
            >
              <div>{item.icon}</div>
            </div>
          </TooltipTrigger>
          <TooltipContent>{item.name}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </Link>
  );
}
