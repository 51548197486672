import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import formatDateToString from "@/utils/FormatDateToString";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";

export default function TopPartsStatsTable({ data, loading, endDate }) {
  if (loading) {
    return (
      <div className="w-1/2 h-full bg-paper p-4 space-x-4 rounded-lg flex flex-col items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (data) {
    return (
      <div className="w-1/2 h-full bg-paper p-4 space-x-4 rounded-lg flex flex-col">
        <div>
          <span className="font-semibold">
            {"Spare Parts with Highest Total Expense"}
          </span>
        </div>
        <div className="w-full">
          <Table>
            <TableCaption>
              Showing 5 most recent records as of {formatDateToString(endDate)}
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Part Name</TableHead>
                <TableHead className="text-right">Number of Receipts</TableHead>
                <TableHead className="text-right">Total Quantity</TableHead>
                <TableHead className="text-right">Average Price</TableHead>
                <TableHead className="text-right">Total Expense</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((row, n) => (
                <TableRow className="cursor-pointer" key={`row-${n}`}>
                  <TableCell className="font-medium">{row.part_name}</TableCell>
                  <TableCell className="text-right">
                    {formatNumberWithCommas(row.count)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatNumberWithCommas(row.total_quantity)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatNumberWithCommas(row.average_price_per_unit)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatNumberWithCommas(row.total_expense)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}
