import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useState } from "react";
import ViewPartsReceipt from "../dialogs/VIewPartsReceipt";
import formatDateToString from "@/utils/FormatDateToString";
import DeletePartsReceipt from "../dialogs/DeletePartsReceipt";
import useDeletePartsReceipt from "@/hooks/useDeletePartsReceipt";
import useRecentPartsReceipts from "@/hooks/useRecentPartsReceipts";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";

export default function RecentPartsExpense({
  data,
  currency,
  startDate,
  endDate,
}) {
  const { data: partsReceipts, loadingParts } = useRecentPartsReceipts(
    data.id,
    currency,
    startDate,
    endDate
  );

  // state for view parts receipt dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const handleReceiptClick = (receipt) => {
    setSelectedReceipt(receipt);
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  // state for delete dialog
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteClick = (receipt) => {
    setIsDeleteDialogOpen(true);
    setIsDialogOpen(false);
  };

  // delete receipt function from hook
  const { deleteReceipt, loading, error } = useDeletePartsReceipt();

  const handleDelete = async (receiptId) => {
    await deleteReceipt(receiptId);
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteClose = () => {
    setIsDeleteDialogOpen(false);
  };

  if (loadingParts) {
    return (
      <div className="truck-overview-container w-1/2 bg-paper p-4 space-x-4 rounded-lg flex flex-col items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    partsReceipts && (
      <div className="truck-overview-container w-1/2 bg-paper p-4 space-x-4 rounded-lg flex flex-col">
        <div>
          <span className="font-semibold">{"Recent Spare Parts Receipts"}</span>
        </div>
        <div className="w-full">
          <Table>
            <TableCaption>
              Showing 5 most recent records as of {formatDateToString(endDate)}
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>Part Name</TableHead>
                <TableHead className="text-right">Quantity</TableHead>
                <TableHead className="text-right">Price per Unit</TableHead>
                <TableHead className="text-right">Total Amount</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {partsReceipts.map((row, n) => (
                <TableRow
                  className="cursor-pointer"
                  key={`row-${n}`}
                  onClick={() => handleReceiptClick(row)}
                >
                  <TableCell className="font-medium">
                    {row.date_of_purchase}
                  </TableCell>
                  <TableCell>{row.part_name}</TableCell>
                  <TableCell className="text-right">{row.quantity}</TableCell>
                  <TableCell className="text-right">
                    {row.price_per_unit}
                  </TableCell>
                  <TableCell className="text-right">{row.total_cost}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <ViewPartsReceipt
              vehicleData={data}
              selectedReceipt={selectedReceipt}
              isOpen={isDialogOpen}
              onClose={handleClose}
              onClick={handleDeleteClick}
            />
            <DeletePartsReceipt
              selectedReceipt={selectedReceipt}
              isOpen={isDeleteDialogOpen}
              onClose={handleDeleteClose}
              onDelete={handleDelete}
            />
          </Table>
        </div>
      </div>
    )
  );
}
