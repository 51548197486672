import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Returns data for a single vehicle
function useVehicle(vehicleId) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadVehicleData = async () => {
      if (!vehicleId) {
        setError("No vehicle ID provided");
        setData(null);
        setLoading(false);
        return;
      }

      try {
        const result = await fetchData(`/api/vehicles/${vehicleId}/`);
        setData(result);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    loadVehicleData();
  }, [vehicleId]);

  return { data, loading, error };
}

export default useVehicle;
