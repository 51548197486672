import { Link } from "react-router-dom";
import { SheetDescription, SheetTrigger } from "@/components/ui/sheet";

export default function MenuSheetItem({ item, activePage, handlePageClick }) {
  const isActive = item.name === activePage;
  return (
    <Link key={item.name} to={item.route}>
      <SheetTrigger className="w-full">
        <SheetDescription
          className={`flex w-full justify-left py-2 space-x-2 p-4 rounded-xl ${
            isActive ? "bg-primary-main text-white" : ""
          }`}
          onClick={() => {
            handlePageClick(item.name);
          }}
        >
          <div>{item.icon}</div>
          <div className="text-md">{item.name}</div>
        </SheetDescription>
      </SheetTrigger>
    </Link>
  );
}
