import React from "react";

export default function LocationInfoWindow({ location }) {
  // create a Date object
  let date = new Date(location.time);

  // get the date in the format 'Month dd, yyyy'
  let dateStr = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // get the time in the format 'hh:mm AM/PM'
  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let timeStr = hours + ":" + minutes + " " + ampm; // '03:04 AM'

  return (
    <div
      className="flex flex-col space-y-2 p-2"
      style={{ minWidth: "15vw", marginLeft: "10" }}
    >
      <h2 className="text-xl" style={{ color: "#BA000D" }}>
        {dateStr}
      </h2>

      <div className="flex flex-col">
        <div className="font-semibold">Time</div>
        <p>{timeStr}</p>
      </div>

      <div className="flex flex-col">
        <div className="font-semibold">Speed</div>
        <p>{location.speed} km/h</p>
      </div>

      <div className="flex flex-col">
        <div className="font-semibold">Fuel Level</div>
        <p>{location.fuel_level} litres</p>
      </div>
    </div>
  );
}
