import MapLayout from "@/layouts/MapLayout";
import ControlPanel from "@/components/controlpanel";
import { useState } from "react";
import TripPlaybackPanel from "./TripPlaybackPanel";
import AnimatedTripPlayback from "./AnimatedTripPlayback";

export default function Trips() {
  const [routeHistory, setRouteHistory] = useState({
    data: [],
    sampled_data: [],
  });

  return (
    <MapLayout>
      <ControlPanel>
        <TripPlaybackPanel
          routeHistory={routeHistory}
          setRouteHistory={setRouteHistory}
        />
      </ControlPanel>
      <AnimatedTripPlayback
        locations={routeHistory.data}
        sampledLocations={routeHistory.data_sampled}
        routeSummary={routeHistory.route_summary}
        stops={routeHistory.stops}
      />
    </MapLayout>
  );
}
