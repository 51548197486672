import Map from "@/components/icons/Map";
import { FuelIcon, TimerIcon } from "lucide-react";
import CalendarDays from "@/components/icons/CalendarDays";

export default function RouteSummary({ data }) {
  return (
    data && (
      <div className="bg-slate-50 shadow-lg w-1/3 rounded-lg p-6 flex-col space-y-4 absolute right-2 transform -translate-x-1/5 top-1/2 pb-16 -translate-y-1/4">
        <div className="text-sm font-semibold uppercase">Route Summary</div>
        <SummaryMetric
          name="Start Time"
          value={data.start_time}
          icon={<CalendarDays />}
        />
        <SummaryMetric
          name="End Time"
          value={data.end_time}
          icon={<CalendarDays />}
        />
        <SummaryMetric
          name="Route Duration"
          value={data.duration}
          icon={<TimerIcon />}
        />
        <SummaryMetric
          name="Idle Time"
          value={data.idle_time}
          icon={<TimerIcon />}
        />
        <SummaryMetric
          name="Distance Travelled"
          value={data.distance_travelled}
          icon={<Map />}
        />
        <SummaryMetric
          name="Fuel Consumed"
          value={data.total_fuel_consumed}
          icon={<FuelIcon />}
        />
        <SummaryMetric
          name="Fuel Efficiency"
          value={data.fuel_efficiency}
          icon={<FuelIcon />}
        />
      </div>
    )
  );
}

function SummaryMetric({ name, value, icon }) {
  return (
    <div className="flex border rounded-lg p-2 bg-paper">
      <div className="flex items-center justify-center px-4">{icon}</div>
      <div className="flex-col ">
        <div className="text-md font-semibold">{name}</div>
        <div className="text-md">{value}</div>
      </div>
    </div>
  );
}
