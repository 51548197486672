import TrackingPanel from "./TrackingPanel";
import MapLayout from "@/layouts/MapLayout";
import SelectedCard from "./cards/SelectedCard";
import useLocations from "@/hooks/useLocations";
import ControlPanel from "@/components/controlpanel";
import { useState, useRef, useCallback } from "react";
import TrackingMap from "@/components/map/trackingmap";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";

export default function Tracking() {
  // location data for fleet
  const { data, center, zoom, loading } = useLocations();

  // single truck selected
  const [selectedAsset, setSelectedAsset] = useState(null);

  // reference to map instance for e.g. zooming
  const mapRef = useRef(null);

  // function to handle vehicle select
  const onAssetSelect = useCallback(({ longitude, latitude, zoom }) => {
    mapRef.current?.flyTo({
      center: [longitude + 0.0025, latitude],
      zoom: zoom ? zoom : 16,
      duration: 2000,
    });
  }, []);

  // Function to select an asset by its id
  const selectAssetById = useCallback(
    (vehicleId) => {
      const asset = data.find((asset) => asset.vehicle_id === vehicleId);
      setSelectedAsset(asset);
    },
    [data, setSelectedAsset]
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen w-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (data) {
    return (
      <MapLayout>
        <ControlPanel>
          <TrackingPanel
            data={data}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            onAssetSelect={onAssetSelect}
            center={center}
            zoom={zoom}
          />
        </ControlPanel>
        <TrackingMap
          locations={data}
          center={center}
          zoom={zoom}
          mapRef={mapRef}
          onAssetSelect={onAssetSelect}
          selectAssetById={selectAssetById}
        />
        {selectedAsset && <SelectedCard selectedAsset={selectedAsset} />}
      </MapLayout>
    );
  }
}
