import React from "react";
import SelectedVehicleCard from "./SelectedVehicleCard";
import SelectedAssetCard from "./SelectedAssetCard";

export default function SelectedCard({ selectedAsset }) {
  if (selectedAsset.source === "vehicle") {
    return <SelectedVehicleCard selectedVehicle={selectedAsset} />;
  } else if (selectedAsset.source === "asset") {
    return <SelectedAssetCard selectedAsset={selectedAsset} />;
  }
}
