import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Returns data for a single vehicle
function useRecentFuelReceipts(vehicleId, currency, startDate, endDate) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Add startDate and endDate to the dependency array
  useEffect(() => {
    setLoading(true);
    const loadReceipts = async () => {
      if (!vehicleId) {
        setError("No vehicleId provided");
        setData(null);
        setLoading(false);
        return;
      }

      // Building the query string with optional startDate and endDate
      let queryString = `/api/financials/fuel/recent-receipts?vehicle_id=${vehicleId}&currency=${currency}`;
      if (startDate) {
        queryString += `&start_date=${startDate}`;
      }
      if (endDate) {
        queryString += `&end_date=${endDate}`;
      }

      try {
        const result = await fetchData(queryString);
        setData(result);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    loadReceipts();
  }, [vehicleId, currency, startDate, endDate]);

  return { data, loading, error };
}

export default useRecentFuelReceipts;
