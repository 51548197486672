import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import formatDateToString from "@/utils/FormatDateToString";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";

export default function InvoiceStatsTable({ data, loading, endDate }) {
  if (loading) {
    return (
      <div className="w-1/2 h-full bg-paper p-4 space-x-4 rounded-lg flex flex-col items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (data) {
    return (
      <div className="w-1/2 h-full bg-paper p-4 space-x-4 rounded-lg flex flex-col">
        <div>
          <span className="font-semibold">{"Invoices by Clients"}</span>
        </div>
        <div className="w-full">
          <Table>
            <TableCaption>
              Showing 5 most recent records as of {formatDateToString(endDate)}
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Client Name</TableHead>
                <TableHead className="text-right">Number of Invoices</TableHead>
                <TableHead className="text-right">
                  Average Invoice Amount
                </TableHead>
                <TableHead className="text-right">Total Amount</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((row, n) => (
                <TableRow className="cursor-pointer" key={`row-${n}`}>
                  <TableCell className="font-medium">{row.client}</TableCell>
                  <TableCell className="text-right">
                    {formatNumberWithCommas(row.count)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatNumberWithCommas(row.average_amount)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatNumberWithCommas(row.total_amount)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}
