import React, { useState, useEffect, useRef } from "react";
import useVehicleSearch from "@/hooks/useVehicleSearch";

const AutocompleteVehicleSearch = ({ setSelectedVehicle }) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { data: vehicles } = useVehicleSearch(searchTerm);
  const wrapperRef = useRef(null); // Create a ref for the component

  // Event handler to close the dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  // Effect to add an event listener to the document
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef} className="w-full relative">
      <input
        className="mt-4 flex h-10 text-sm w-full px-4 rounded-lg border border-input bg-background px-3 py-2 text-lg placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-none"
        type="text"
        placeholder="Search for a vehicle..."
        value={searchTerm}
        onChange={(e) => {
          setOpen(true);
          setSearchTerm(e.target.value);
        }}
      />
      {open && (
        <ul className="absolute w-full top-full left-0 mt-2 bg-slate-50 rounded-lg z-10">
          {vehicles?.map((vehicle) => (
            <li
              key={vehicle.id}
              className="cursor-pointer p-2 hover:bg-slate-200"
              onClick={() => {
                setSearchTerm(vehicle.registration_number);
                setSelectedVehicle(vehicle);
                setOpen(false);
              }}
            >
              {vehicle.registration_number}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteVehicleSearch;
