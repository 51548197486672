import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import formatDateToString from "@/utils/FormatDateToString";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";

function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    return (
      <div className="bg-slate-50 p-2 min-w-200">
        <div className="text-sm font-semibold">{payload[0].payload.date}</div>
        <div>
          {"Total Revenue"}: {formatNumberWithCommas(payload[0].value)}{" "}
          {payload[0].payload.currency}
        </div>
      </div>
    );
  }

  return null;
}

export default function InvoiceAmountOverTime({ data, loading }) {
  // Function to calculate the ticks for the X-axis
  const calculateTicks = (data) => {
    if (!data || data.length === 0) {
      // If data is null, undefined, or empty, return an empty array
      return [];
    }

    if (data.length <= 3) {
      // If there are 3 or fewer points, use all dates as ticks
      return data.map((d) => d.date);
    } else {
      // If more, use first, middle, and last dates
      const middleIndex = Math.floor(data.length / 2);
      return [data[0].date, data[middleIndex].date, data[data.length - 1].date];
    }
  };

  // Calculate the ticks to use on the X-axis
  const xAxisTicks = calculateTicks(data);

  // Function to calculate the range for Y-axis
  const calculateYAxisRange = (data) => {
    if (!data || data.length === 0) return [0, 1]; // Default range

    let minPrice = data[0].average_price;
    let maxPrice = data[0].average_price;
    data.forEach((item) => {
      if (item.average_price < minPrice) minPrice = item.average_price;
      if (item.average_price > maxPrice) maxPrice = item.average_price;
    });

    const buffer = (maxPrice - minPrice) * 0.1; // 10% buffer on each side
    return [Math.max(0, minPrice - buffer), maxPrice + buffer];
  };

  // Calculate the range for the Y-axis
  const yAxisRange = calculateYAxisRange(data);

  // Function to format the Y-axis tick labels
  const formatYAxisTick = (value) => {
    return formatNumberWithCommas(value); // Restricts the label to 2 decimal places
  };

  if (loading) {
    return (
      <div className="w-1/2 h-full bg-paper p-4 space-x-4 rounded-lg flex flex-col items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (data) {
    return (
      <div className="w-1/2 h-full bg-paper p-4 rounded-lg flex flex-col">
        <div>
          <span className="font-semibold pb-4">
            {"Total Revenue Over Time"}
          </span>
        </div>
        <ResponsiveContainer width="95%" height="90%">
          <LineChart
            data={data}
            margin={{
              top: 40,
              right: 30,
              left: 30,
              bottom: 20,
            }}
          >
            <CartesianGrid
              horizontal={true}
              vertical={false}
              stroke="#ccc"
              strokeDasharray="3 3"
            />
            <XAxis
              className="pt-10"
              dataKey="date"
              tickFormatter={formatDateToString}
              ticks={xAxisTicks}
            />
            <YAxis tickFormatter={formatYAxisTick} />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="total_invoice_amount"
              stroke="#35589A"
              activeDot={{ r: 8 }}
              strokeWidth={3}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
