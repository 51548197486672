import { Navigate } from "react-router-dom";
import { useAuth } from "@/auth/AuthContext";

function getUserType() {
  return localStorage.getItem("user_type");
}

function DashboardRoute({ children }) {
  const { isAuthenticated } = useAuth();
  const userType = getUserType();
  const isUserType = ["admin_user", "financial_user"].includes(userType);

  if (!isAuthenticated || !isUserType) {
    // Redirect to / if not authenticated or not the right user type
    return <Navigate to="/" replace />;
  }

  return children;
}

export default DashboardRoute;
