import Card from "./Card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

export default function HeadlineNumbers({ data }) {
  return (
    <div className={"flex mt-1 space-x-2 items-center justify-between"}>
      {data.map((cardData, i) => {
        return (
          <Dialog>
            <DialogTrigger asChild>
              <div
                key={`card-${i}`}
                className="flex items-center justify-center w-full bg-paper rounded-lg cursor-pointer hover:shadow-sm"
              >
                <Card cardData={cardData} />
              </div>
            </DialogTrigger>

            <DialogContent>
              <DialogHeader>
                <DialogTitle>{cardData.name}</DialogTitle>
                <DialogDescription>
                  <div className="flex-col space-y-4 mt-2">
                    <div>{cardData.desc}</div>
                    {cardData.percentage !== "inf" && (
                      <div>{cardData.period}</div>
                    )}
                  </div>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        );
      })}
    </div>
  );
}
