import PercentageBadge from "./PercentageBadge";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";

export default function Card({ cardData }) {
  return (
    <div className="flex flex-col py-8 space-y-1 items-center justify-center">
      <div className="flex space-x-2">
        {cardData.icon}
        <div className="text-xl font-semibold text-slate-700">
          {cardData.name}
        </div>
      </div>
      {cardData.loading && (
        <div className="flex items-center">
          <LoadingSpinner height={36} width={36} />
        </div>
      )}
      {!cardData.loading && (
        <div className="flex space-x-2 items-center">
          <div className="text-3xl">{cardData.value}</div>
          <PercentageBadge value={cardData.percentage} />
        </div>
      )}
    </div>
  );
}
