import CurrencyTab from "./CurrencyTab";
import formatDateToString from "@/utils/FormatDateToString";
import DateSelector from "./DateSelector";
import AddFuelReceipt from "../dialogs/AddFuelReceipt";
import AddPartsReceipt from "../dialogs/AddPartsReceipt";
import AddInvoice from "../dialogs/AddInvoice";

export default function TopButtonRow({
  setCurrency,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  vehicleData,
}) {
  return (
    <div className="w-full flex mt-4 justify-between">
      <div className="flex space-x-4">
        <CurrencyTab setCurrency={setCurrency} />
        <DateSelector setStartDate={setStartDate} setEndDate={setEndDate} />

        <div className="flex items-center pl-4 text-sm text-gray-600">
          Showing data from {formatDateToString(startDate)} to{" "}
          {formatDateToString(endDate)}
        </div>
      </div>

      <div className="flex space-x-4">
        <AddInvoice vehicleData={vehicleData} />
        <AddFuelReceipt vehicleData={vehicleData} />
        <AddPartsReceipt vehicleData={vehicleData} />
      </div>
    </div>
  );
}
