//import HomeIcon from "@/components/icons/HomeIcon";
import FleetIcon from "@/components/icons/FleetIcon";
import LocationIcon from "@/components/icons/LocationIcon";
import DashboardIcon from "@/components/icons/DashboardIcon";

const MenuItems = [
  //{
  //  name: "Home",
  //  icon: <HomeIcon />,
  //  route: "/",
  //},
  {
    name: "Dispatch",
    icon: <LocationIcon />,
    route: "/dispatch",
  },
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    route: "/dashboard",
  },
  {
    name: "Fleet",
    icon: <FleetIcon />,
    route: "/fleet",
  },
  //  {
  //    name: "Expenses",
  //    icon: <DollarIcon />,
  //    route: "/",
  //  },
];

export default MenuItems;
