import { cn } from "@/lib/utils";
import { useState } from "react";
import { format } from "date-fns";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import CalendarDays from "@/components/icons/CalendarDays";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

export default function CustomDatePicker({
  className,
  setStartDate,
  setEndDate,
  setSelected,
  closeDialog,
}) {
  const [date, setDate] = useState();

  const handleDateSelection = (selectedDate) => {
    setDate(selectedDate);
    if (selectedDate && selectedDate.from && selectedDate.to) {
      setStartDate(format(selectedDate.from, "yyyy-MM-dd"));
      setEndDate(format(selectedDate.to, "yyyy-MM-dd"));
      setSelected("Custom");
      closeDialog();
    }
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarDays />
            {date?.from ? (
              date.to ? (
                <div className="ml-2">
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </div>
              ) : (
                <div className="ml-2">{format(date.from, "LLL dd, y")}</div>
              )
            ) : (
              <span className="ml-2">Select Dates</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleDateSelection}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
