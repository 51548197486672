import TopRow from "../toprow";
import { useState } from "react";
import AppLayout from "@/layouts/AppLayout";
import dateOptions from "@/lib/dateOptions";
import PartsHeadline from "./PartsHeadline";
import TopPartsStatsTable from "./TopPartsStatsTable";
import useTopPartsStats from "@/hooks/useTopPartsStats";
import DistributionBarChart from "@/components/barchart";
import SparePartsExpenseOverTime from "./SparePartsExpenseOverTime";
import useTotalSparePartsExpense from "@/hooks/useTotalSparePartsExpense";
import useSparePartsExpenseOverTime from "@/hooks/useSparePartsExpenseOverTime";
import useSparePartsExpenseByVehicle from "@/hooks/useSparePartsExpenseByVehicle";

export default function PartsDashboard() {
  const [currency, setCurrency] = useState("USD");
  const [startDate, setStartDate] = useState(
    dateOptions["Last Month"]["startDate"]
  );
  const [endDate, setEndDate] = useState(dateOptions["Last Month"]["endDate"]);

  const {
    data: totalSparePartsExpense,
    loading: loadingTotalSparePartsExpense,
  } = useTotalSparePartsExpense(startDate, endDate, currency);

  const {
    data: sparePartsExpenseByVehicle,
    loading: loadingSparePartsExpenseByVehicle,
  } = useSparePartsExpenseByVehicle(startDate, endDate, currency);

  const { data: topPartsStats, loading: loadingTopPartsStats } =
    useTopPartsStats(startDate, endDate, currency);

  const { data: partsExpenseOverTime, loading: loadingPartsExpenseOverTime } =
    useSparePartsExpenseOverTime(startDate, endDate, currency);

  return (
    <AppLayout>
      <TopRow
        setCurrency={setCurrency}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        prevStartDate={
          totalSparePartsExpense ? totalSparePartsExpense.prev_start_date : null
        }
        prevEndDate={
          totalSparePartsExpense ? totalSparePartsExpense.prev_end_date : null
        }
      />
      <PartsHeadline
        startDate={startDate}
        endDate={endDate}
        currency={currency}
        totalSparePartsExpense={totalSparePartsExpense}
        loadingTotalSparePartsExpense={loadingTotalSparePartsExpense}
      />
      <DistributionBarChart
        data={sparePartsExpenseByVehicle}
        loading={loadingSparePartsExpenseByVehicle}
        chartTitle="Spare Parts Expense by Vehicle"
        dataKey="total_spare_parts_expense"
        dataLabel="Spare Parts Expense"
      />

      <div className="flex w-full space-x-2 h-2/5">
        <TopPartsStatsTable
          data={topPartsStats}
          loading={loadingTopPartsStats}
          endDate={endDate}
        />

        <SparePartsExpenseOverTime
          data={partsExpenseOverTime}
          loading={loadingPartsExpenseOverTime}
        />
      </div>
    </AppLayout>
  );
}
