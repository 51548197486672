import CurrentLocationMap from "@/components/map/CurrentLocationMap";
import useLocation from "@/hooks/useLocation";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";

export default function CurrentLocation({ data }) {
  // pull location data for truck id
  const { data: locationData, loading } = useLocation("vehicle", data.id);

  if (loading) {
    return (
      <div className="flex flex-col h-full items-center justify-center bg-paper rounded-lg p-4">
        <LoadingSpinner />
      </div>
    );
  }
  if (locationData) {
    return (
      <div className="flex-1 flex-col h-full bg-paper rounded-lg p-4">
        <div>
          <span className="font-semibold">Current Location</span>
        </div>
        <div className="mt-2 mb-4">
          <span className="text-sm text-slate-600">
            Updated {locationData.time_diff}
          </span>
        </div>

        <CurrentLocationMap location={locationData} />
      </div>
    );
  }
}
