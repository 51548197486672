import { Navigate } from "react-router-dom";
import { useAuth } from "@/auth/AuthContext";

function AdminRoute({ children }) {
  const { isAuthenticated } = useAuth();
  const isStaff = localStorage.getItem("is_staff") === "true";

  if (!isAuthenticated || !isStaff) {
    // Redirect to / if not authenticated or not staff
    return <Navigate to="/" replace />;
  }

  return children;
}

export default AdminRoute;
