import {
  Dialog,
  DialogTrigger,
  DialogContentNoClose,
} from "@/components/ui/dialog";
import Recents from "./Recents";
import { useState } from "react";
import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";
import useVehicleSearch from "@/hooks/useVehicleSearch";
import SearchIcon from "@/components/icons/SearchIcon";
import NoResults from "./NoResults";

export default function Search() {
  // initialize state of search bar
  const [inputValue, setInputValue] = useState("");

  const { data, loading, error } = useVehicleSearch(inputValue);

  // update state when input changes
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDialogClose = () => {
    setInputValue("");
  };
  return (
    <Dialog onOpenChange={handleDialogClose}>
      <DialogTrigger asChild>
        <div className="w-full flex space-x-4 py-4 px-4 border border-slate-200 hover:bg-slate-200 hover:cursor-pointer rounded-lg lg:w-3/4">
          <div>
            <SearchIcon />
          </div>
          <div>Tap to Search</div>
        </div>
      </DialogTrigger>
      <DialogContentNoClose className="translate-y-[-60%] h-[50vh]">
        <div className="flex-col">
          <SearchBar handleInputChange={handleInputChange} />

          {data === null && <Recents />}
          {data && data.length > 0 && <SearchResults data={data} />}
          {data && data.length === 0 && <NoResults />}
        </div>
      </DialogContentNoClose>
    </Dialog>
  );
}
