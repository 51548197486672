import Menu from "./Menu";
import { useLocation } from "react-router-dom";

export default function ControlPanel({ children }) {
  const location = useLocation();
  const activeState = stateMap[location.pathname];

  return (
    <div className="flex flex-col py-8 px-4 w-2/5 bg-paper rounded-lg">
      <Menu activeState={activeState} />
      {children}
    </div>
  );
}

// Current state for menu
const stateMap = {
  "/dispatch/tracking": "tracking",
  "/dispatch/route-history": "route-history",
  "/dispatch/trips": "trips",
};
