import React, { useEffect, useRef } from "react";

export default function SearchBar({ handleInputChange }) {
  const inputRef = useRef(null);

  useEffect(() => {
    // Automatically focus the input when the component is mounted
    inputRef.current.focus();
  }, []);
  return (
    <div className="rounded-lg">
      <input
        ref={inputRef}
        type="text"
        placeholder="Search for a vehicle ..."
        className="w-full p-4 text-lg border border-gray-300 rounded focus:outline-none focus:border-blue-200"
        style={{ boxShadow: "none" }}
        onChange={handleInputChange}
      />
    </div>
  );
}
