import {
  BarChart,
  Bar,
  CartesianGrid,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
} from "recharts";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandSeparator,
} from "@/components/ui/command";
import { Check, ChevronDown } from "lucide-react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";

import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";

function CustomTooltip({ active, payload, dataLabel }) {
  if (active && payload && payload.length) {
    return (
      <div className="bg-slate-50 p-2 min-w-200">
        <div className="text-sm font-semibold">
          {payload[0].payload.registration_number}
        </div>
        <div>
          {dataLabel}: {formatNumberWithCommas(payload[0].value)}{" "}
          {payload[0].payload.currency}
        </div>
      </div>
    );
  }

  return null;
}

const sortOptions = {
  All: [{ value: "All" }],

  Top: [
    { value: "Top 10" },
    { value: "Top 25" },
    { value: "Top 50" },
    { value: "Top 100" },
  ],

  Bottom: [
    { value: "Bottom 10" },
    { value: "Bottom 25" },
    { value: "Bottom 50" },
    { value: "Bottom 100" },
  ],
};

const RoundedBar = (props) => {
  const { fill, x, y, width, height } = props;

  return (
    <Rectangle
      radius={[3, 3, 0, 0]}
      fill={fill}
      x={x}
      y={y}
      width={width}
      height={height}
    />
  );
};

export default function DistributionBarChart({
  data,
  loading,
  chartTitle,
  dataKey,
  dataLabel,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("Top 100");

  const filteredData = () => {
    if (!Array.isArray(data)) {
      return [];
    }

    let sortedData = [...data].sort((a, b) => b[dataKey] - a[dataKey]);

    switch (value) {
      case "All":
        return sortedData;
      case "Top 10":
        return sortedData.slice(0, 10);
      case "Top 25":
        return sortedData.slice(0, 25);
      case "Top 50":
        return sortedData.slice(0, 50);
      case "Top 100":
        return sortedData.slice(0, 100);
      case "Bottom 10":
        return sortedData.slice(-10);
      case "Bottom 25":
        return sortedData.slice(-25);
      case "Bottom 50":
        return sortedData.slice(-50);
      case "Bottom 100":
        return sortedData.slice(-100);
      default:
        return data;
    }
  };

  return (
    <div className="bg-paper p-4 rounded-lg h-1/3">
      <div className="flex justify-between">
        <div className="container-header font-semibold mb-4">{chartTitle}</div>
        {!loading && (
          <div className="pr-8">
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="w-[140px] justify-center"
                >
                  {value}
                  <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command>
                  <CommandGroup>
                    {sortOptions["All"].map((option) => (
                      <CommandItem
                        key={option.value}
                        value={option.value}
                        onSelect={() => {
                          setValue(option.value);
                          setOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            value === option.value ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {option.value}
                      </CommandItem>
                    ))}
                    <CommandSeparator />

                    {sortOptions["Top"].map((option) => (
                      <CommandItem
                        key={option.value}
                        value={option.value}
                        onSelect={() => {
                          setValue(option.value);
                          setOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            value === option.value ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {option.value}
                      </CommandItem>
                    ))}
                    <CommandSeparator />

                    {sortOptions["Bottom"].map((option) => (
                      <CommandItem
                        key={option.value}
                        value={option.value}
                        onSelect={() => {
                          setValue(option.value);
                          setOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            value === option.value ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {option.value}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
        )}
      </div>
      {loading && (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner />
        </div>
      )}
      {!loading && data && (
        <ResponsiveContainer width="99%" height="90%">
          <BarChart
            data={filteredData()}
            margin={{
              top: 30,
              right: 30,
              left: 30,
              bottom: 30,
            }}
            onClick={(e) => {
              if (e && e.activePayload) {
                const clickedBarData = e.activePayload[0].payload;
                if (clickedBarData) {
                  navigate(`/fleet/${clickedBarData.vehicle_id}`);
                }
              }
            }}
          >
            <CartesianGrid
              horizontal={true}
              vertical={false}
              stroke="#ccc"
              strokeDasharray="3 3"
            />
            <YAxis tickFormatter={formatNumberWithCommas} />
            <Tooltip content={<CustomTooltip dataLabel={dataLabel} />} />
            <Bar dataKey={dataKey} fill="#35589A" shape={<RoundedBar />} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
