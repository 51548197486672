import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import truckIcon from "@/assets/truck-icon.svg";
import locationDot from "@/assets/location-dot.svg";

// Map Component
// -------------------------------------------------------------------- //
export default function Map({ location }) {
  const [mapApi, setMapApi] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [marker, setMarker] = useState(null);
  const [animationInterval, setAnimationInterval] = useState(null);

  // map props
  const defaultProps = {
    center: {
      lat: location?.message.latitude || -0.22,
      lng: location?.message.longitude || 35.78,
    },
    zoom: 17,
  };

  const lerp = (start, end, ratio) => start + (end - start) * ratio;

  const animateMarkerMovement = (newLat, newLng) => {
    const duration = 1000; // duration of the animation in milliseconds
    const steps = 20; // number of steps in the animation
    const timePerStep = duration / steps;
    let step = 0;

    const startLat = marker.getPosition().lat();
    const startLng = marker.getPosition().lng();

    // Clear existing animation if any
    if (animationInterval) clearInterval(animationInterval);

    const interval = setInterval(() => {
      step++;
      const ratio = step / steps;

      const lat = lerp(startLat, newLat, ratio);
      const lng = lerp(startLng, newLng, ratio);

      marker.setPosition(new mapApi.LatLng(lat, lng));
      mapInstance.setCenter(marker.getPosition());

      if (step === steps) {
        clearInterval(interval);
        setAnimationInterval(null);
      }
    }, timePerStep);

    setAnimationInterval(interval);
  };

  useEffect(() => {
    if (mapApi && location && mapInstance) {
      if (marker) {
        // Animate the marker to the new position
        animateMarkerMovement(
          location.message.latitude,
          location.message.longitude
        );
      } else {
        // Create the marker for the first time
        const newMarker = new mapApi.Marker({
          map: mapInstance,
          position: new mapApi.LatLng(
            location.message.latitude,
            location.message.longitude
          ),
          icon: {
            url: locationDot,
            scaledSize: new mapApi.Size(20, 20),
            labelOrigin: new mapApi.Point(15, 40),
            // Handle rotation if necessary
          },
        });
        setMarker(newMarker);
      }
    }
  }, [location, mapApi, mapInstance]);

  const handleApiLoaded = (map, maps) => {
    setMapInstance(map);
    setMapApi(maps);
  };

  return (
    <div className="w-full h-full">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDHS1vzHhthX0r8z6NARZ8ofngg9boXHIU" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        options={{ mapId: "79ba85b61acfbcf1", disableDefaultUI: true }}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      />
    </div>
  );
}
