import ArrowUpRight from "@/components/icons/ArrowUpRight";
import ArrowDownRight from "@/components/icons/ArrowDownRight";
import { Badge } from "@/components/ui/badge";

export default function PercentageBadge({ value }) {
  const up = value > 0;
  const label = value.toString() + "%";

  // colors based on direction
  const badgeColor = up ? "bg-green-100" : "bg-red-100";
  const textColor = up ? "text-green-500" : "text-red-500";
  const hexTextColor = up ? "#22c55e" : "#ef4444";

  // arrow direction based on whether pct > 0
  const arrow = up ? (
    <ArrowUpRight fillColor={hexTextColor} />
  ) : (
    <ArrowDownRight fillColor={hexTextColor} />
  );

  if (value === "inf") {
    return (
      <Badge
        className={`flex justify-center bg-gray-200 h-6 w-16 space-x-1 p-2 hover:bg-gray-200`}
      >
        <div className="text-gray-600">N/A</div>
      </Badge>
    );
  }

  return (
    <Badge
      className={`flex justify-center ${badgeColor} h-6 w-16 space-x-1 p-2 hover:${badgeColor}`}
    >
      <div className={`${textColor}`}>{label}</div>
      <div>{arrow}</div>
    </Badge>
  );
}
