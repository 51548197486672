import TopRowMenu from "./menu";
import { useLocation } from "react-router-dom";
import formatDateToString from "@/utils/FormatDateToString";
import CurrencyTab from "@/pages/fleet/vehicle/toprow/CurrencyTab";
import DateSelector from "@/pages/fleet/vehicle/toprow/DateSelector";

export default function TopRow({
  setCurrency,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  prevStartDate,
  prevEndDate,
}) {
  const location = useLocation();
  const activeState = stateMap[location.pathname];

  return (
    <div className="w-full flex mt-4">
      <div className="flex space-x-4">
        <TopRowMenu activeState={activeState} />
        <CurrencyTab setCurrency={setCurrency} />
        <DateSelector setStartDate={setStartDate} setEndDate={setEndDate} />

        <div className="flex flex-col items-center pl-4">
          <div className="text-sm text-gray-600">
            Showing data from {formatDateToString(startDate)} to{" "}
            {formatDateToString(endDate)}
          </div>
          {prevStartDate && prevEndDate && (
            <div className="text-sm text-slate-400">
              Comparing against {formatDateToString(prevStartDate)} to{" "}
              {formatDateToString(prevEndDate)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// Current state for menu
const stateMap = {
  "/dashboard/invoices": "invoices",
  "/dashboard/fuel": "fuel",
  "/dashboard/parts": "parts",
};
