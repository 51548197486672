import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import ReactSpeedometer from "react-d3-speedometer";
import containerRender from "@/assets/images/containerRender.png";
import TAT100Image from "@/assets/images/tat100.png";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";

// ---- Selected Truck Card Component ------------------------
export default function SelectedAssetCard({ selectedContainer }) {
  const navigate = useNavigate();
  return (
    <div className="bg-card flex flex-col p-4 h-[95%] w-1/3 shadow-lg rounded-lg absolute right-2 transform -translate-x-1/5 top-1/2 -translate-y-1/2">
      <CardHeader selectedContainer={selectedContainer} />
      <div className="font-semibold mt-10">CONTAINER SPEED (KM/H)</div>
      <Speedometer speed={selectedContainer.speed} />

      <div className="flex space-x-4 mt-4">
        <Tracker />

        <BatteryStatus batteryVoltage={selectedContainer.battery_voltage} />
      </div>

      <div className="justify-end mt-auto flex flex-col space-y-2">
        <Button
          disabled
          className="w-full"
          variant="outline"
          onClick={() => {
            navigate(`/fleet/${selectedContainer.truck_id}`);
          }}
        >
          View Additional Details
        </Button>
      </div>
    </div>
  );
}

// ---- Header Components  ------------------------
function ContainerImage() {
  return (
    <div className="ml-auto flex justify-center">
      <img className="w-[100%]" src={containerRender} alt="container" />
    </div>
  );
}

function CardHeader({ selectedContainer }) {
  return (
    <div className="w-full flex items-center">
      <div className="flex-col w-4/5">
        <div className="font-semibold text-xl">
          {selectedContainer.container_number}
        </div>
        <div className="font-light text-sm">{selectedContainer.time_diff}</div>
      </div>
      <ContainerImage />
    </div>
  );
}

// ---- Speedometer  ------------------------
function Speedometer({ speed }) {
  return (
    <div className="w-full mt-2 p-2 flex justify-center bg-slate-50 rounded-lg border">
      <ReactSpeedometer
        height={200}
        maxValue={80}
        value={speed}
        needleColor="rgb(107 114 128)"
        segments={4}
        segmentColors={[
          "rgb(219 234 254)",
          "rgb(191 219 254)",
          "rgb(147 197 253)",
          "rgb(96 165 250)",
        ]}
        textColor="black"
        ringWidth={20}
        currentValueText={speed + " km/h"}
      />
    </div>
  );
}

// ---- Tracker ------------------------
const Tracker = () => {
  return (
    <div className="w-1/2 mt-2 p-2 flex flex-col">
      <div className="font-semibold">TRACKER</div>
      <div className="mt-2 flex flex-col bg-slate-50 rounded-lg border h-full">
        <img src={TAT100Image} alt="container" />
      </div>
    </div>
  );
};

// ---- Battery  ------------------------
const BatteryStatus = ({ batteryVoltage }) => {
  const levelPercentage = (batteryVoltage / 7200) * 100;
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset =
    circumference - (levelPercentage / 100) * circumference;

  return (
    <div className="w-1/2 mt-2 p-2 flex flex-col">
      <div className="font-semibold">BATTERY STATUS</div>
      <div className="mt-2 flex bg-slate-50 rounded-lg border h-full items-center justify-center">
        <svg width="100" height="100" className="m-2">
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="transparent"
            stroke="#e6e6e6"
            strokeWidth="10"
          />
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="transparent"
            stroke="rgb(22 163 74)" // Change color based on percentage if desired
            strokeWidth="10"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            transform="rotate(-90 50 50)"
          />
          <text
            className="text-lg font-bold"
            x="50"
            y="55"
            fill="black"
            fontSize="15"
            textAnchor="middle"
          >
            {`${levelPercentage.toFixed(0)}%`}
          </text>
        </svg>
      </div>
    </div>
  );
};
