import Funnel from "@/components/icons/Funnel";
import HeadlineNumbers from "@/components/headline";
import RevenueIcon from "@/components/icons/RevenueIcon";
import formatDateToString from "@/utils/FormatDateToString";
import CalculatorIcon from "@/components/icons/CalculatorIcon";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";
import formatNumberWithLetters from "@/utils/formatNumberWithLetters";

import useFuelStationCount from "@/hooks/useFuelStationCount";
import useAverageFuelPrice from "@/hooks/useAverageFuelPrice";
import useTotalFuelConsumption from "@/hooks/useTotalFuelConsumption";
import useTotalFuelReceiptCount from "@/hooks/useTotalFuelReceiptCount";

export default function FuelHeadline({
  startDate,
  endDate,
  currency,
  fuelExpense,
  loadingFuelExpense,
}) {
  const { data: fuelConsumption, loading: loadingFuelConsumption } =
    useTotalFuelConsumption(startDate, endDate);

  const { data: fuelReceiptCount, loading: loadingFuelReceiptCount } =
    useTotalFuelReceiptCount(startDate, endDate);

  const { data: averageFuelPrice, loading: loadingAverageFuelPrice } =
    useAverageFuelPrice(startDate, endDate, currency);

  const { data: fuelStationCount, loading: loadingFuelStationCount } =
    useFuelStationCount(startDate, endDate);

  const FuelCards = [
    {
      name: "Total Fuel Expense",
      icon: <RevenueIcon />,
      value: fuelExpense
        ? `${formatNumberWithLetters(
            fuelExpense.total_fuel_expense.toFixed(2)
          )} ${fuelExpense.total_fuel_expense_currency}`
        : "",
      percentage: fuelExpense ? fuelExpense.percent_change : "",
      desc: `Total fuel expense incurred from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: fuelExpense
        ? `This amount is ${
            fuelExpense.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            fuelExpense.percent_change
          )}% from the previous period: ${formatDateToString(
            fuelExpense.prev_start_date
          )} to ${formatDateToString(fuelExpense.prev_end_date)}.`
        : "",
      loading: loadingFuelExpense,
    },
    {
      name: "Total Fuel Consumption",
      icon: <Funnel fillColor="rgb(124 58 237)" />,
      value: fuelConsumption
        ? `${formatNumberWithCommas(fuelConsumption.total_fuel_consumption)} L`
        : "",
      percentage: fuelConsumption ? fuelConsumption.percent_change : "",
      desc: `Total fuel consumed (in litres) from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: fuelConsumption
        ? `This amount is ${
            fuelConsumption.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            fuelConsumption.percent_change
          )}% from the previous period: ${formatDateToString(
            fuelConsumption.prev_start_date
          )} to ${formatDateToString(fuelConsumption.prev_end_date)}.`
        : "",
      loading: loadingFuelConsumption,
    },
    {
      name: "Number of Refuels",
      icon: <CalculatorIcon fillColor="rgb(37 99 235)" />,
      value: fuelReceiptCount
        ? formatNumberWithCommas(fuelReceiptCount.total_fuel_receipts)
        : "",
      percentage: fuelReceiptCount ? fuelReceiptCount.percent_change : "",
      desc: `Total number of fuel receipts from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: fuelReceiptCount
        ? `This amount is ${
            fuelReceiptCount.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            fuelReceiptCount.percent_change
          )}% from the previous period: ${formatDateToString(
            fuelReceiptCount.prev_start_date
          )} to ${formatDateToString(fuelReceiptCount.prev_end_date)}.`
        : "",
      loading: loadingFuelReceiptCount,
    },
    {
      name: "Average Price Per Litre",
      icon: <RevenueIcon />,
      value: averageFuelPrice
        ? `${averageFuelPrice.average_price_per_litre} ${averageFuelPrice.average_price_currency}`
        : "",
      percentage: averageFuelPrice ? averageFuelPrice.percent_change : "",
      desc: `Average price per litre of fuel from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: averageFuelPrice
        ? `This amount is ${
            averageFuelPrice.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            averageFuelPrice.percent_change
          )}% from the previous period: ${formatDateToString(
            averageFuelPrice.prev_start_date
          )} to ${formatDateToString(averageFuelPrice.prev_end_date)}.`
        : "",
      loading: loadingAverageFuelPrice,
    },
    {
      name: "Number of Fuel Stations",
      icon: <CalculatorIcon fillColor="rgb(37 99 235)" />,
      value: fuelStationCount ? fuelStationCount.total_fuel_stations : "",
      percentage: fuelStationCount ? fuelStationCount.percent_change : "",
      desc: `Number of fuel stations visited from ${formatDateToString(
        startDate
      )} to ${formatDateToString(endDate)}`,
      period: fuelStationCount
        ? `This amount is ${
            fuelStationCount.percent_change > 0 ? "up" : "down"
          } ${Math.abs(
            fuelStationCount.percent_change
          )}% from the previous period: ${formatDateToString(
            fuelStationCount.prev_start_date
          )} to ${formatDateToString(fuelStationCount.prev_end_date)}.`
        : "",
      loading: loadingFuelStationCount,
    },
  ];
  return <HeadlineNumbers data={FuelCards} />;
}
