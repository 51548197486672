import { useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { Map, Source, Layer } from "react-map-gl";
import {
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
  registrationNumberLayer,
} from "./layers";
import ThemeSelector from "./ThemeSelector";

export default function TrackingMap({
  locations,
  center,
  zoom,
  mapRef,
  onAssetSelect,
  selectAssetById,
}) {
  // state to handle theme
  const [theme, setTheme] = useState("Default");

  // handler to zoom to cluster on click
  const onClick = (event) => {
    if (!event.features || event.features.length === 0) {
      return; // No features at the click location, so do nothing
    }

    const feature = event.features[0];

    // Check if the feature is a cluster
    if (feature.properties.cluster_id) {
      const clusterId = feature.properties.cluster_id;
      const mapboxSource = mapRef.current.getSource("location");

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return; // Handle the error appropriately
        }

        mapRef.current.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 500,
        });
      });
    } else if (feature.properties.registration_number) {
      // This is an unclustered point, call onAssetSelect
      onAssetSelect({
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
      });
      selectAssetById(feature.properties.vehicleId);
    }
  };

  // create geojson from location data
  const vehicleData = {
    type: "FeatureCollection",
    features: locations.map((loc) => ({
      type: "Feature",
      properties: {
        vehicleId: loc.vehicle_id,
        vehicleType: loc.vehicle_type,
        registration_number: loc.registration_number,
      },
      geometry: {
        type: "Point",
        coordinates: [loc.longitude, loc.latitude],
      },
    })),
  };

  return (
    <div className="w-full pl-4">
      <Map
        mapLib={import("mapbox-gl")}
        initialViewState={{
          latitude: center ? center.latitude : -1.5,
          longitude: center ? center.longitude : 35.5,
          zoom: zoom ? zoom - 2 : 6,
          bearing: 0,
          pitch: 0,
        }}
        interactiveLayerIds={[
          clusterLayer.id,
          unclusteredPointLayer.id,
          registrationNumberLayer.id,
        ]}
        //mapStyle="mapbox://styles/mapbox/navigation-day-v1"
        mapStyle={themeOptions[theme]}
        mapboxAccessToken="pk.eyJ1Ijoic2FybWFkazIzIiwiYSI6ImNsdmxmc201MjJidjQybW14Z2s4a3JybWMifQ.Ju0cjLAcCpvvSqt0UKQ5rg"
        ref={mapRef}
        onClick={onClick}
        projection={"globe"}
      >
        <Source
          id="location"
          type="geojson"
          data={vehicleData}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
          <Layer {...registrationNumberLayer} />
        </Source>
        <ThemeSelector theme={theme} setTheme={setTheme} />
      </Map>
    </div>
  );
}

// -- Theme Mapping
const themeOptions = {
  Default: "mapbox://styles/mapbox/navigation-day-v1",
  Light: "mapbox://styles/mapbox/light-v11",
  Dark: "mapbox://styles/mapbox/dark-v11",
  Satellite: "mapbox://styles/mapbox/satellite-streets-v11",
};
