import axios from "axios";

export default async function deleteData(url) {
  const accessToken = localStorage.getItem("access_token");

  if (!accessToken) {
    throw new Error("No access token found in local storage.");
  }

  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to delete data: ${error.response?.statusText || error.message}`
    );
  }
}
