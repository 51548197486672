import { useState } from "react";
import supportedCurrencies from "@/lib/supportedCurrencies";

export default function CurrencyTab({ setCurrency }) {
  const [selectedTab, setSelectedTab] = useState("USD");

  const handleTabClick = (e) => {
    const tab = e.target.value;
    setSelectedTab(tab);
    setCurrency(tab);
  };

  return (
    <div className="flex flex-col bg-slate-200 px-1 rounded-lg items-center justify-center">
      <ul className="flex flex-row space-x-2 h-[90%]">
        {supportedCurrencies.map((currency) => (
          <li
            key={currency}
            className="cursor-pointer text-sm font-medium text-center"
          >
            <button
              className={`py-2 px-4 rounded-lg text-gray-700 hover:text-gray-900 
                          ${
                            selectedTab === currency
                              ? "bg-paper rounded-lg"
                              : ""
                          }`}
              onClick={handleTabClick}
              value={currency}
            >
              {currency}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
