import { useState } from "react";
import KeyMetrics from "./KeyMetrics";
import useVehicle from "@/hooks/useVehicle";
import AppLayout from "@/layouts/AppLayout";
import { useParams } from "react-router-dom";
import dateOptions from "@/lib/dateOptions";
import CurrentLocation from "./CurrentLocation";
import TopButtonRow from "./toprow/TopButtonRow";
import RecentFuelExpense from "./tables/RecentFuelExpense";
import RecentPartsExpense from "./tables/RecentPartsExpense";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import RecentInvoices from "./tables/RecentInvoices";
import DriverScorecard from "./DriverScorecard";
import VehicleOverview from "./VehicleOverview";

export default function Vehicle() {
  const { vehicleId } = useParams();
  const { data: vehicleData, loading } = useVehicle(vehicleId);

  const [currency, setCurrency] = useState("USD");
  const [startDate, setStartDate] = useState(
    dateOptions["This Month"]["startDate"]
  );
  const [endDate, setEndDate] = useState(dateOptions["This Month"]["endDate"]);
  const [vehicleProfit, setVehicleProfit] = useState({
    amount: 0,
    currency: "KES",
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen w-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (vehicleData) {
    return (
      <AppLayout>
        <TopButtonRow
          setCurrency={setCurrency}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          vehicleData={vehicleData}
        />
        <div className="flex flex-col mt-4">
          <div className="flex space-x-4">
            <div className="w-2/3 h-screen[80%]">
              <VehicleOverview
                data={vehicleData}
                currency={currency}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setVehicleProfit={setVehicleProfit}
              />
            </div>
            <div className="w-1/5">
              <KeyMetrics
                data={vehicleData}
                currency={currency}
                startDate={startDate}
                endDate={endDate}
                vehicleProfit={vehicleProfit}
              />
            </div>
            <div className="w-1/5">
              <CurrentLocation data={vehicleData} />
            </div>
          </div>

          <div className="flex space-x-4 mt-4">
            <div className="flex w-full space-x-4">
              <RecentInvoices
                data={vehicleData}
                currency={currency}
                startDate={startDate}
                endDate={endDate}
              />
              <DriverScorecard
                vehicleData={vehicleData}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>

          <div className="flex space-x-4 mt-4">
            <div className="flex w-full space-x-4">
              <RecentFuelExpense
                data={vehicleData}
                currency={currency}
                startDate={startDate}
                endDate={endDate}
              />
              <RecentPartsExpense
                data={vehicleData}
                currency={currency}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </div>
      </AppLayout>
    );
  }
}
