import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Returns data for a single truck
function useLocation(assetType, assetId) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadLocationData = async () => {
      setLoading(true);
      if (!assetId | !assetType) {
        setError("Please provide assetType and assetId");
        setData(null);
        setLoading(false);
        return;
      }

      try {
        const result = await fetchData(
          `/api/location/latest/${assetType}/${assetId}/`
        );
        setData(result);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    loadLocationData();
  }, [assetType, assetId]);

  return { data, loading, error };
}

export default useLocation;
