import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// menu components
import MenuItems from "./MenuItems";
import MobileNavbar from "./mobile/MobileNavbar";
import DesktopNavbar from "./desktop/DesktopNavbar";

// resolves path to find active path
const findActivePage = (pathname) => {
  // If the pathname is exactly '/', it's the Home page
  if (pathname === "/") {
    return "Home";
  }

  // Find the first menu item where the pathname starts with the item's route
  // and is not the root '/' route
  const activeItem = MenuItems.find(
    (item) => item.route !== "/" && pathname.startsWith(item.route)
  );

  return activeItem ? activeItem.name : "Home";
};

function Navbar() {
  // pull active page to intialize state
  const location = useLocation();
  const initialActivePage = findActivePage(location.pathname);
  const [activePage, setActivePage] = useState(initialActivePage);

  useEffect(() => {
    // Update active page when the location changes
    const newActivePage = findActivePage(location.pathname);
    setActivePage(newActivePage);
  }, [location.pathname]); // Dependency array with location.pathname

  const handlePageClick = (page) => {
    setActivePage(page);
  };
  return (
    <>
      <DesktopNavbar
        activePage={activePage}
        handlePageClick={handlePageClick}
      />
      <MobileNavbar activePage={activePage} handlePageClick={handlePageClick} />
    </>
  );
}

export default Navbar;
