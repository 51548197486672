export const clusterLayer = {
  id: "clusters",
  type: "circle",
  source: "location",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "rgba(59, 130, 246, .8)",
      3,
      "rgba(234, 179, 8, .8)",
      5,
      "rgba(239, 68, 68, .8)",
    ],
    "circle-radius": ["step", ["get", "point_count"], 15, 3, 20, 5, 25],
  },
};

export const clusterCountLayer = {
  id: "cluster-count",
  type: "symbol",
  source: "location",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 16,
  },
  paint: {
    "text-color": "rgb(30, 41, 59)",
  },
};

export const unclusteredPointLayer = {
  id: "unclustered-point",
  type: "circle",
  source: "location",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": "#3B82F6",
    "circle-radius": 8,
    "circle-stroke-width": 4,
    "circle-stroke-color": "rgba(191, 219, 254, .6)",
  },
};

export const registrationNumberLayer = {
  id: "registration-number",
  type: "symbol",
  source: "location",
  filter: ["!", ["has", "point_count"]], // This ensures it only applies to unclustered points
  layout: {
    "text-field": ["get", "registration_number"], // Accessing the registration number from properties
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
    "text-anchor": "top", // Anchors the text above the point
    "text-offset": [0, 1], // Adjusts the position to appear above the point
  },
  paint: {
    "text-color": "#000000", // Sets the text color, change as needed
  },
};
