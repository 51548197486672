import { useState } from "react";
import fetchData from "@/utils/FetchData";
import { Button } from "@/components/ui/button";
import CustomDatePicker from "../routehistory/CustomDatePicker";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import AutocompleteVehicleSearch from "@/components/search/AutocompleteVehicleSearch";
import { Separator } from "@/components/ui/separator";

export default function TripPlaybackPanel({ routeHistory, setRouteHistory }) {
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleButtonClick = async () => {
    if (selectedVehicle && startDate && endDate) {
      setLoading(true);
      try {
        const data = await fetchRouteHistory(
          startDate,
          endDate,
          selectedVehicle.id
        );
        setRouteHistory(data);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please select a vehicle and date range.");
    }
  };

  if (error) {
    window.alert(error);
    setError(null);
  }

  const handleResetClick = () => {
    window.location.reload();
  };

  // You can use loading and error states to show feedback in UI

  return (
    <div className="h-full flex flex-col p-2">
      <div className="flex-grow space-y-4">
        <AutocompleteVehicleSearch setSelectedVehicle={setSelectedVehicle} />
        <CustomDatePicker setStartDate={setStartDate} setEndDate={setEndDate} />
        <Separator />
        <div className="bg-slate-50 rounded-lg p-6 flex-col space-y-4">
          <div className="text-sm font-semibold uppercase">Trip Playback</div>
          <div className="text-md  text-gray-600">
            Explore trips and idle periods. Choose a vehicle and specify a day
            to get started. <br />
            <br />
            Trip Playback presents a visual timeline of each trip and idling
            session, offering vital statistics like duration, distance, and
            stationary times. Gain in-depth understanding of your fleet's
            operational patterns and optimize for efficiency.
          </div>
        </div>
      </div>
      {loading && (
        <div className="flex w-full justify-center">
          <LoadingSpinner />
        </div>
      )}
      {!loading && (
        <div className="flex flex-col w-full space-y-4">
          {routeHistory.route_summary && (
            <Button variant="outline" onClick={handleResetClick}>
              Reset
            </Button>
          )}
          <Button onClick={handleButtonClick}>Playback Trip</Button>
        </div>
      )}
    </div>
  );
}

// Function to fetch route history
async function fetchRouteHistory(startDate, endDate, vehicleId) {
  if (!startDate || !endDate || !vehicleId) {
    throw new Error("startDate, endDate, or vehicleId missing");
  }

  let url = `/api/location/vehicle-route-history?start_date=${startDate}&end_date=${endDate}&vehicle_id=${vehicleId}`;
  return await fetchData(url);
}
