import { useState } from "react";
import { postData } from "@/utils/PostData";

function useSMSGetRecord() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitSMSGetRecord = async (trackerId) => {
    setLoading(true);
    setData(null);
    setError(null);

    try {
      const result = await postData(
        `/api/trackers/sms/get-record/${trackerId}/`
      );
      setData(result);
    } catch (e) {
      setError(e.message || "An error occurred while submitting the SMS");
    } finally {
      setLoading(false);
    }
  };

  return { submitSMSGetRecord, data, loading, error };
}

export default useSMSGetRecord;
