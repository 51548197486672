import { useState } from "react";
import MapLayout from "@/layouts/MapLayout";
import ControlPanel from "@/components/controlpanel";
import RouteHistoryPanel from "./RouteHistoryPanel";
import RouteHistoryMap from "./RouteHistoryMap";

export default function RouteHistory() {
  const [routeHistory, setRouteHistory] = useState({
    data: [],
    sampled_data: [],
  });

  return (
    <MapLayout>
      <ControlPanel>
        <RouteHistoryPanel
          routeHistory={routeHistory}
          setRouteHistory={setRouteHistory}
        />
      </ControlPanel>
      <RouteHistoryMap
        locations={routeHistory.data}
        sampledLocations={routeHistory.data_sampled}
        routeSummary={routeHistory.route_summary}
      />
    </MapLayout>
  );
}
