import { Link } from "react-router-dom";
import { Separator } from "@/components/ui/separator";

export default function TopRowMenu({ activeState }) {
  return (
    <div className="flex space-x-4 items-center w-[20vw]">
      <Separator orientation="vertical" />
      <TabLink
        label="Invoices"
        to="/dashboard/invoices"
        isActive={activeState === "invoices"}
      />
      <Separator orientation="vertical" />
      <TabLink
        label="Fuel"
        to="/dashboard/fuel"
        isActive={activeState === "fuel"}
      />
      <Separator orientation="vertical" />
      <TabLink
        label="Spare Parts"
        to="/dashboard/parts"
        isActive={activeState === "parts"}
      />
      <Separator orientation="vertical" />
    </div>
  );
}

const TabLink = ({ label, to, isActive }) => (
  <Link
    to={to}
    className={`cursor-pointer text-blue-950 uppercase ${
      isActive ? "font-bold border-b-2 border-blue-950" : ""
    }`}
  >
    {label}
  </Link>
);
