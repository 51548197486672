function formatNumberWithCommas(number, decimalPlaces = 0) {
  if (number) {
    return number.toLocaleString("en-US", {
      maximumFractionDigits: decimalPlaces,
    });
  } else {
    return "0";
  }
}

export default formatNumberWithCommas;
