import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Hook for fetching vehicle metrics data
function useScorecard(startDate, endDate, vehicleId) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      if (!startDate || !endDate || !vehicleId) {
        setError("startDate, endDate, or vehicleId missing");
        setData(null);
        setLoading(false);
        return;
      }

      try {
        let url = `/api/location/scorecard?start_date=${startDate}&end_date=${endDate}&vehicle_id=${vehicleId}`;
        const result = await fetchData(url);
        setData(result);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [startDate, endDate, vehicleId]);

  return { data, loading, error };
}

export default useScorecard;
