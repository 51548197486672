import MenuSheet from "./MenuSheet";
import LogoMobile from "../LogoMobile";

export default function MobileNavbar({ activePage, handlePageClick }) {
  return (
    <div className="flex min-w-full h-20 items-center justify-between bg-slate-100 p-4 lg:hidden">
      <div>
        <LogoMobile />
      </div>
      <MenuSheet activePage={activePage} handlePageClick={handlePageClick} />
    </div>
  );
}
