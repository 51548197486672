import * as z from "zod";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { zodResolver } from "@hookform/resolvers/zod";
import { DatePicker } from "@/components/ui/datepicker";
import supportedCurrencies from "@/lib/supportedCurrencies";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "@/components/ui/form";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectItem,
  SelectContent,
} from "@/components/ui/select";

// define schema
const formSchema = z.object({
  vehicle: z.number(),
  fuel_station: z.string().max(100),
  price_per_litre: z.number().min(0).max(1000000),
  currency: z.string(),
  volume_filled: z.number().min(0).max(1000000),
  date_of_purchase: z.date(),
});

export default function AddFuelReceiptForm({ vehicleData, submitFuelReceipt }) {
  // create form
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      vehicle: vehicleData.id,
      fuel_station: "",
      price_per_litre: "",
      currency: "KES",
      volume_filled: "",
      date_of_purchase: new Date(),
    },
  });

  // get form values
  const { getValues } = form;

  // watch price and volume to calculate total cost
  const [totalCost, setTotalCost] = useState(0);
  const { watch, setValue } = form;
  const pricePerLitre = watch("price_per_litre");
  const volumeFilled = watch("volume_filled");

  useEffect(() => {
    if (pricePerLitre && volumeFilled) {
      const totalCost = pricePerLitre * volumeFilled;
      setTotalCost(totalCost);
    }
  }, [pricePerLitre, volumeFilled, setValue]);

  async function onSubmit(values) {
    // Clone the values object to avoid mutating the original object
    let formattedValues = { ...values };

    // Check if the date field exists and is a Date object
    if (formattedValues.date_of_purchase instanceof Date) {
      // Format the date to YYYY-MM-DD
      const year = formattedValues.date_of_purchase.getFullYear();
      const month = (formattedValues.date_of_purchase.getMonth() + 1)
        .toString()
        .padStart(2, "0"); // Adding 1 because months are 0-indexed
      const day = formattedValues.date_of_purchase
        .getDate()
        .toString()
        .padStart(2, "0");

      formattedValues.date_of_purchase = `${year}-${month}-${day}`;
    }

    // Format the price_per_litre and total_cost with the selected currency
    formattedValues.price_per_litre_currency = values.currency;

    formattedValues.total_cost = totalCost;
    formattedValues.total_cost_currency = values.currency;

    delete formattedValues.currency;

    await submitFuelReceipt(formattedValues);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="bg-paper">
        <FormField
          name="vehicle"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Vehicle</FormLabel>
              <FormControl>
                <Input
                  disabled
                  {...field}
                  value={vehicleData.registration_number}
                  placeholder={vehicleData.registration_number}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="fuel_station"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Fuel Station</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Fuel Station"
                  onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="price_per_litre"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Price</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  placeholder="Price (per litre)"
                  step={0.01}
                  onChange={(e) => field.onChange(Number(e.target.value))}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="currency"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Currency</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select currency" />
                  </SelectTrigger>
                  <SelectContent>
                    {supportedCurrencies.map((currency) => (
                      <SelectItem key={currency} value={currency}>
                        {currency}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="volume_filled"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Volume Filled</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  placeholder="Volume Filled (litres)"
                  step={0.01}
                  onChange={(e) => field.onChange(Number(e.target.value))}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="date_of_purchase"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Date of Purchase</FormLabel>
              <FormControl>
                <DatePicker
                  onDateSelect={(date) =>
                    form.setValue("date_of_purchase", date)
                  }
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Separator className="mt-8 mb-1" />

        <div className="flex w-full justify-between px-4 my-8">
          <div>
            <div className="text-lg font-light">Total Cost</div>
          </div>
          <div>
            <div className="text-lg">
              {formatNumberWithCommas(totalCost)} {getValues("currency")}
            </div>
          </div>
        </div>

        <Button className="w-full mt-4" type="submit">
          + Add Fuel Receipt
        </Button>
      </form>
    </Form>
  );
}
