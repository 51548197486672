import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Returns data for a single vehicle
function useDriverDetails(vehicleId) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      if (!vehicleId) {
        setError("No vehicle ID provided");
        setData(null);
        setLoading(false);
        return;
      }

      try {
        const result = await fetchData(`/api/drivers/vehicle/${vehicleId}/`);
        setData(result);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [vehicleId]);

  return { data, loading, error };
}

export default useDriverDetails;
