function getQuarterDates(date) {
  const quarter = Math.floor(date.getMonth() / 3);
  const firstDay = new Date(date.getFullYear(), quarter * 3, 1);
  return {
    start: firstDay.toISOString().split("T")[0],
  };
}

function formatDate(date) {
  // Adjust the date to the local time zone
  const localTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localTime.toISOString().split("T")[0];
}

function getLastMonthDates() {
  const date = new Date();
  const firstDayLastMonth = new Date(
    date.getFullYear(),
    date.getMonth() - 1,
    1
  );
  const lastDayLastMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  return {
    start: formatDate(firstDayLastMonth),
    end: formatDate(lastDayLastMonth),
  };
}

function getLastQuarterDates() {
  const date = new Date();
  const currentQuarter = Math.floor(date.getMonth() / 3);
  const firstDayLastQuarter = new Date(
    date.getFullYear(),
    (currentQuarter - 1) * 3,
    1
  );
  const lastDayLastQuarter = new Date(
    date.getFullYear(),
    currentQuarter * 3,
    0
  );
  return {
    start: formatDate(firstDayLastQuarter),
    end: formatDate(lastDayLastQuarter),
  };
}

const currentDate = new Date();
const firstDayOfMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1
);

const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
const lastDayOfLastYear = new Date(currentDate.getFullYear() - 1, 11, 31);
const firstDayOfLastYear = new Date(currentDate.getFullYear() - 1, 0, 1);
const { start: firstDayOfQuarter } = getQuarterDates(currentDate);
const { start: firstDayOfLastMonth, end: lastDayOfLastMonth } =
  getLastMonthDates();
const { start: firstDayOfLastQuarter, end: lastDayOfLastQuarter } =
  getLastQuarterDates();

const dateOptions = {
  "This Month": {
    startDate: formatDate(firstDayOfMonth),
    endDate: formatDate(currentDate),
  },
  "This Quarter": {
    startDate: firstDayOfQuarter,
    endDate: formatDate(currentDate),
  },
  "This Year": {
    startDate: formatDate(firstDayOfYear),
    endDate: formatDate(currentDate),
  },
  "Last Month": {
    startDate: firstDayOfLastMonth,
    endDate: lastDayOfLastMonth,
  },
  "Last Quarter": {
    startDate: firstDayOfLastQuarter,
    endDate: lastDayOfLastQuarter,
  },
  "Last Year": {
    startDate: formatDate(firstDayOfLastYear),
    endDate: formatDate(lastDayOfLastYear),
  },
};

export default dateOptions;
