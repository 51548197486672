import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

export default function DeleteFuelReceipt({
  selectedReceipt,
  isOpen,
  onClose,
  onDelete,
}) {
  const handleDeleteClick = () => {
    onDelete(selectedReceipt.id);
    window.location.reload();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <div className="flex-col space-y-4">
          <div className="text-lg font-semibold">Confirm Delete</div>
          <div className="pb-2">
            <p>
              Are you sure you want to delete this fuel receipt?
              <br />
            </p>
            <p>This action cannot be undone. </p>
          </div>
          <Button onClick={handleDeleteClick}>Delete</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
