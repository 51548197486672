import GoogleMapReact from "google-map-react";
import React, { useState, useEffect } from "react";
import LocationInfoWindow from "./LocationInfoWindow";
import ReactDOMServer from "react-dom/server";
import RouteSummary from "./RouteSummary";

export default function RouteHistoryMap({
  locations,
  sampledLocations,
  routeSummary,
}) {
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [polyline, setPolyline] = useState(null);
  const [heatmap, setHeatmap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [mapType, setMapType] = useState("roadmap"); // Default to roadmap

  const defaultProps = {
    center: {
      lat: -1.5,
      lng: 35.5,
    },
    zoom: 7,
  };

  const [center, setCenter] = useState({
    lat: defaultProps.center.lat,
    lng: defaultProps.center.lng,
  });
  const [zoom, setZoom] = useState(routeSummary?.zoom ?? defaultProps.zoom);

  useEffect(() => {
    // Update center and zoom when routeSummary changes
    if (routeSummary) {
      setCenter({
        lat: routeSummary.center_latitude,
        lng: routeSummary.center_longitude + routeSummary.offset,
      });
      setZoom(routeSummary.zoom);
    }
  }, [routeSummary]);

  useEffect(() => {
    if (map && maps) {
      // Clear existing polyline, heatmap and markers
      if (polyline) {
        polyline.setMap(null);
      }
      if (heatmap) {
        heatmap.setMap(null);
      }
      markers.forEach((marker) => marker.setMap(null));

      setPolyline(null);
      setHeatmap(null);
      setMarkers([]); // Reset the markers in state

      // If there are no locations, don't attempt to create polyline, heatmap or markers
      if (locations.length === 0) {
        return;
      }

      let currentInfoWindow = null; // Keep track of the current InfoWindow

      // Create arrow marker for each sampled location to handle hover events
      let newMarkers = [];

      sampledLocations.forEach((location) => {
        const arrowMarker = new maps.Marker({
          position: { lat: location.latitude, lng: location.longitude },
          map: map,
          icon: {
            path: maps.SymbolPath.FORWARD_CLOSED_ARROW,
            fillColor: "#BA000D",
            fillOpacity: 1,
            strokeWeight: 1,
            rotation: location.angle,
            scale: 5, // Adjust scale to make arrow visible
          },
        });

        arrowMarker.addListener("click", () => {
          if (currentInfoWindow) {
            currentInfoWindow.close();
          }
          const newInfoWindow = new maps.InfoWindow({
            content: ReactDOMServer.renderToString(
              <LocationInfoWindow location={location} />
            ),
            position: arrowMarker.position,
          });
          newInfoWindow.open(map);
          currentInfoWindow = newInfoWindow;
        });

        newMarkers.push(arrowMarker);
      });

      setMarkers(newMarkers);

      // Create new Polyline
      const newPolyline = new maps.Polyline({
        path: locations.map((location) => ({
          lat: location.latitude,
          lng: location.longitude,
        })),
        strokeColor: "#BA000D",
        strokeOpacity: 0.9,
        strokeWeight: 5,
      });
      newPolyline.setMap(map);

      // Create new HeatmapLayer
      const newHeatmap = new maps.visualization.HeatmapLayer({
        data: locations.map(
          (location) => new maps.LatLng(location.latitude, location.longitude)
        ),
        options: { radius: 50 },
      });
      newHeatmap.setMap(map);

      setPolyline(newPolyline);
      setHeatmap(newHeatmap);
    }
  }, [map, maps, locations, sampledLocations]);

  const onGoogleApiLoaded = ({ map, maps }) => {
    map.addListener("tilesloaded", function () {
      setMap(map);
      setMaps(maps);
    });
  };

  return (
    <div className="w-full h-full pl-4 relative">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDHS1vzHhthX0r8z6NARZ8ofngg9boXHIU",
          libraries: "visualization",
        }}
        center={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={{
          mapTypeId: mapType,
          mapId: "79ba85b61acfbcf1",
          disableDefaultUI: true,
        }}
      />
      {routeSummary && <RouteSummary data={routeSummary} />}
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 30,
          zIndex: 5,
        }}
      >
        <select
          value={mapType}
          onChange={(e) => setMapType(e.target.value)}
          style={{
            padding: "10px", // Add more padding inside the select
            border: "2px solid #bbbbbb", // A solid border with a custom color
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
            borderRadius: "5px", // Optional: if you want rounded corners
            backgroundColor: "white", // Ensures the background is white
            cursor: "pointer", // Changes the cursor to indicate it's clickable
          }}
        >
          <option value="roadmap">Default</option>
          <option value="satellite">Satellite</option>
          <option value="hybrid">Hybrid</option>
          <option value="terrain">Terrain</option>
        </select>
      </div>
    </div>
  );
}
